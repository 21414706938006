import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Regsucesspopup from '../../assets/Images/Regsucesspopup.png';
import { Grid } from '@mui/material';
import './RegisterSuccessPopup.css'

const RegisterSuccessPopup = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    return(
        <>
            <Dialog
                fullScreen={fullScreen}
                open={props.registerUserPopup}
                onClose={props.close}
                aria-labelledby="responsive-dialog-title"
            >
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} >
                        <Grid container>
                            <Grid item xs={12} sx={{ textAlign:"center", marginTop:"10px" }}>
                                <img src={Regsucesspopup} />
                            </Grid>  
                            <Grid item xs={12} sx={{ textAlign:"center", marginTop:"30px" }} >
                                <Grid container>
                                    <Grid item xs={12} sx={{ marginBottom:"20px" }}><span id="reg-sucess-popup-text">Account for the new agent has been created successfully.</span></Grid>
                                    <Grid item xs={12}><span id="reg-sucess-popup-text">We have sent a welcome email with login credentials to <span id="reg-sucess-popup-text2">johnk@mail.com</span></span></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ textAlign:"center", marginTop:"30px"}}>
                                <Grid container className="reg-popup-close-btn">
                                    <Grid item xs={4}>           
                                          <Button variant='contained' id="reg-popup-close-btn" onClick={props.close} >Close</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </DialogContent>
           
        </Dialog>
        
        </>
    );
}

export default RegisterSuccessPopup;