import { Button, Checkbox, Dialog, DialogContent, Grid, Link } from '@mui/material';
import React from 'react'
import "./Termconditionpopup.css";
import { useNavigate } from 'react-router-dom';
import { acceptTermsandConditionAPI } from '../../service/login.service';
import { Context as mainContext } from '../../Context/auth_context'
import { useContext, useState } from "react";
import { UserMeAPI } from '../../service/login.service';





const Termconditionpopup = (props) => {

    const { state } = useContext(mainContext)
    const navigation = useNavigate()

    const acceptTermsandCondition = () => {
        acceptTermsandConditionAPI((res) => {
            if (res.data.status) {
                UserMeAPI((res) => {
                    var userAgreement = res.data.data[0].user_data.user_agreement
                    if (userAgreement) {
                        window.location.reload();
                        navigation('./dashboard')
                    }
                    else {
                        <Termconditionpopup />
                    }
                })
            }
        })
    }
    return (
        <>
            <Dialog
                open={true}
                onClose={props.handleClose}
                aria-labelledby="responsive-dialog-title"
            // fullWidth="md"
            >


                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} textAlign="center">
                            <Grid container>
                                <Grid item xs={12} className="accept-cond-item">
                                    <span id="accept-cond-text">Please Accept <Link id="accept-cond-here" href="/TermsAndConditions" target="_blank">Terms and Conditions </Link></span>
                                </Grid>
                                <Grid item xs={12} >
                                    <Grid container className="accept-cond-btn-container">
                                        <Grid item xs={9.5} className="accept-cond-btn-item">
                                            <Button variant='contained' id="accept-cond-btn" onClick={() => { acceptTermsandCondition() }}>Accept</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

        </>
    )
}

export default Termconditionpopup; 