import { Dialog, DialogContent, Grid, TextField, Typography, Button, Box, InputAdornment, Snackbar, Alert } from "@mui/material"
import './ShareClientProfilePopup.css'
// close-btn
import CloseIcon from '@mui/icons-material/Close';
// copy link
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ClientPageUrlSavePopup from "../ClientPageUrlSavePopup/ClientPageUrlSavePopup";
import ClientPageUrlSaveEmailPopup from "../ClientPageUrlSaveEmailPopup/ClientPageUrlSaveEmailPopup";
import { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getActivityLog, generateClientFacingURL, sendMailProfileEmailAgain, deleteUserProfileUrl, getClientFacingUrlDataAPI, generateClientFacingURLAPI, sendEmailUrlAPI, deleteUserProfileUrlAPI, extendUrlAPI } from '../../service/login.service'
import { useEffect } from "react";
import { Context as mainContext } from '../../Context/auth_context'
import Moment from 'react-moment';
import { useLocation, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ConfirmMessagePopup from "../ConfirmMessagePopup/DeleteLinkPopup";
import ShareDeletePopup from "../ShareDeletePopup/ShareDeletePopup";
import SendEmailPopup from "../SendEmailSuccessPopup/RegenerateUrlPopup";
import DeleteLinkPopup from "../ConfirmMessagePopup/DeleteLinkPopup";
import RegenerateUrlPopup from "../SendEmailSuccessPopup/RegenerateUrlPopup";
import WarningIcon from '@mui/icons-material/Warning';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Warningmark from '../../assets/Images/Warningmark.png'


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.blue,
        color: theme.palette.common.black,

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontWeight: 600,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.blue,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const ShareClientProfilePopup = (props) => {

    let flagRun = false;
    const [urlDetails, setUrlDetails] = useState(true);
    const [urlDetailsData, setUrlDetailsData] = useState({});
    const { UpdateLoader, state, } = useContext(mainContext)
    const [activityLog, setActivityLog] = useState(false);
    const [activityLogData, setActivityLogData] = useState([{}]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [updateSomething, setUpdateSomething] = useState(false);
    const [profileId, setProfileId] = useState(0);
    const [message, setMessage] = useState({
        status: false,
        message: "",
        error: "",
    });
    const [changeEmail, setChangeEmail] = useState(props.data?.inputData?.universalInput?.email)
    const [saveFlag, setSaveFlag] = useState(false)
    const [flagDeleteLinkPopup, setFlagDeleteLinkPopup] = useState(false)
    const [flagPreSharePopup, setFlagPreSharePopup] = useState(false);
    const [flagRegenrateUrlPopup, setFlagRegenrateUrlPopup] = useState(false)
    const [flagExtendUrlPopup, setFlagExtendUrlPopup] = useState(false)


    const handleClose = () => {
        setUrlDetails(true)
        setActivityLog(false)
        setFlagDeleteLinkPopup(false)
        setFlagRegenrateUrlPopup(false)
        setFlagExtendUrlPopup(false)
        setFlagPreSharePopup(false)
    }

    const handleOpen = () => {
        // props.handleOpen()
        // props.setDeleteLinkPopup(true)

    }

    const handleClose1 = () => {
        setMessage({
            status: false,
            message: "",
            error: "",
        })
    };



    const getClientFacingurl = async (profileId) => {
        if (flagRun) {
            return
        }
        flagRun = true;
        await getClientFacingUrlDataAPI(profileId,
            async (res) => {
                if (res.data.status == false) {
                    if (res.data.error.code == 9024) {
                        if (!changeEmail) {
                            return;
                        }
                        let obj = {
                            "user_profile_id": props.data.inputData.profile_id,
                            "email": changeEmail,
                            "send_email_to_client_flag": saveFlag
                        }
                        generateClientFacingURLAPI(obj, (res) => {
                            if (res.data.status == true) {
                                setActivityLogData(res.data.data[0].client_facing_url_activity_log)
                                setUrlDetailsData(res.data.data[0])
                                let userDetails = res.data.data[0]
                                if (userDetails && !userDetails.client_facing_url_token_deleted) {
                                    setSaveFlag(false);
                                }
                                UpdateLoader(false)
                                // setUpdateSomething(!updateSomething)
                                getClientFacingUrlDataAPI(profileId,
                                    (res) => {
                                        setActivityLogData(res.data.data[0].client_facing_url_activity_log)
                                        setUrlDetailsData(res.data.data[0])
                                        let userDetails = res.data.data[0]
                                        if (userDetails && !userDetails.client_facing_url_token_deleted) {
                                            setSaveFlag(false);
                                        }
                                        UpdateLoader(false)
                                        handleClose()
                                        setMessage({
                                            status: true,
                                            message: "URL Generated Successfully",
                                            error: false,
                                        })
                                    },
                                    (err) => {
                                        setMessage({
                                            status: true,
                                            message: "Something Went Wrong!",
                                            error: false,
                                        })
                                    })
                                UpdateLoader(false)
                            }
                            else {
                                setMessage({
                                    status: true,
                                    message: res.data.error.message,
                                    error: true,
                                })
                            }
                        },
                            (err) => {
                                console.log(err)
                                UpdateLoader(false)
                                setMessage({
                                    status: true,
                                    message: "Something Went Wrong!",
                                    error: true,
                                })
                            })
                    }
                    else {
                        setMessage({
                            status: true,
                            message: "Something Went Wrong!",
                            error: true,
                        })
                    }
                }
                else {
                    if (res.data.data[0].client_facing_url_token_deleted) {
                        setFlagPreSharePopup(true)
                    }
                    setActivityLogData(res.data.data[0].client_facing_url_activity_log)
                    setUrlDetailsData(res.data.data[0])
                    let userDetails = res.data.data[0]
                    if (userDetails && !userDetails.client_facing_url_token_deleted) {
                        setSaveFlag(false);
                    }
                    UpdateLoader(false)
                }
            },
            (err) => {
                setMessage({
                    status: true,
                    message: "Something Went Wrong!",
                    error: true,
                })
            }
        )
    }

    useEffect(() => {
        // debugger
        let id = searchParams.get("id");
        setProfileId(id)
        let profileId = (props.data.inputData.profile_id) ? props.data.inputData.profile_id : id
        if (profileId) {
            if (urlDetailsData && urlDetailsData?.client_facing_url_id) {
                return
            } else {

                getClientFacingurl(profileId)
                // if (activityLog) {
                // }
                // else if (urlDetails) {

                //     getClientFacingurl(profileId)
                // }
                // else if (updateSomething) {
                //     getClientFacingurl(profileId)
                // }
            }
        }
    }, [])


    useEffect(() => {
        setChangeEmail(props.data?.inputData?.universalInput?.email)
    }, [props])



    const regenerateUrl = (profileId) => {
        if (!changeEmail) {
            return;
        }

        let obj = {
            "user_profile_id": props.data.inputData.profile_id,
            "email": changeEmail,
            "send_email_to_client_flag": saveFlag
        }

        UpdateLoader(true)
        generateClientFacingURLAPI(obj, (res) => {
            if (res.data.status == true) {
                setActivityLogData(res.data.data[0].client_facing_url_activity_log)
                setUrlDetailsData(res.data.data[0])
                let userDetails = res.data.data[0]
                if (userDetails && !userDetails.client_facing_url_token_deleted) {
                    setSaveFlag(false);
                }
                UpdateLoader(false)
                // setUpdateSomething(!updateSomething)
                getClientFacingUrlDataAPI(profileId,
                    (res) => {
                        setActivityLogData(res.data.data[0].client_facing_url_activity_log)
                        setUrlDetailsData(res.data.data[0])
                        let userDetails = res.data.data[0]
                        if (userDetails && !userDetails.client_facing_url_token_deleted) {
                            setSaveFlag(false);
                        }
                        UpdateLoader(false)
                        handleClose()
                        setMessage({
                            status: true,
                            message: "URL Regenerated Successfully",
                            error: false,
                        })
                    },
                    (err) => {
                        setMessage({
                            status: true,
                            message: "Something Went Wrong!",
                            error: false,
                        })
                    })
                UpdateLoader(false)
            }
            else {
                setMessage({
                    status: true,
                    message: res.data.error.message,
                    error: true,
                })
            }
        },
            (err) => {
                console.log(err)
                UpdateLoader(false)
                setMessage({
                    status: true,
                    message: "Something Went Wrong!",
                    error: true,
                })
            })
    }

    const sendEmail = (profileId) => {

        if (!changeEmail) {
            return;
        }

        let obj = {
            "user_profile_id": searchParams.get("id"),
            "email": changeEmail
        }
        UpdateLoader(true)
        sendEmailUrlAPI(obj, (res) => {
            if (res.data.status) {
                getClientFacingUrlDataAPI(profileId,
                    (res) => {
                        setActivityLogData(res.data.data[0].client_facing_url_activity_log)
                        setUrlDetailsData(res.data.data[0])
                        let userDetails = res.data.data[0]
                        if (userDetails && !userDetails.client_facing_url_token_deleted) {
                            setSaveFlag(false);
                        }
                        setMessage({
                            status: true,
                            message: "Email has been sent successfully!",
                            error: false,
                        })
                        UpdateLoader(false)
                    },
                    (err) => {
                        setMessage({
                            status: true,
                            message: "Something Went Wrong!",
                            error: true,
                        })
                    }
                )
            }
            else {
                setMessage({
                    status: true,
                    message: "Something Went Wrong!",
                    error: true,
                })
            }
            UpdateLoader(false)

        }, (err) => {
            console.log(err)
            UpdateLoader(false)
            setMessage({
                status: true,
                message: "Something Went Wrong!",
                error: true,
            })
        })

    }

    const extendLinkUrl = (profileId) => {

        let token = urlDetailsData.client_facing_url_token

        if (!token) {
            setMessage({
                status: true,
                message: "Something Went Wrong!",
                error: true,
            })
            return
        }

        let regenerateUrlObj = {
            public_token: token,
            user_profile_id: searchParams.get("id")
        }

        extendUrlAPI(regenerateUrlObj,
            (res) => {
                if (res.data.status) {
                    getClientFacingUrlDataAPI(profileId,
                        (res) => {
                            setActivityLogData(res.data.data[0].client_facing_url_activity_log)
                            setUrlDetailsData(res.data.data[0])
                            let userDetails = res.data.data[0]
                            if (userDetails && !userDetails.client_facing_url_token_deleted) {
                                setSaveFlag(false);
                            }
                            setMessage({
                                status: true,
                                message: "URL has been extended successfully!",
                                error: false,
                            })
                            UpdateLoader(false)
                            handleClose()
                        },
                        (err) => {
                            setMessage({
                                status: true,
                                message: "Something Went Wrong!",
                                error: true,
                            })
                        }
                    )
                }
                else {
                    setMessage({
                        status: true,
                        message: "Something Went Wrong!",
                        error: true,
                    })
                }
                UpdateLoader(false)
            },
            (err) => {
                setMessage({
                    status: true,
                    message: "Something Went Wrong!",
                    error: true,
                })
                UpdateLoader(false)
            }
        )
    }

    const regenerateShareURL = (profileId) => {
        if (!changeEmail) {
            return;
        }

        let obj = {
            "user_profile_id": props.data.inputData.profile_id,
            "email": changeEmail,
            "send_email_to_client_flag": saveFlag
        }

        UpdateLoader(true)
        generateClientFacingURLAPI(obj, (res) => {
            if (res.data.status == true) {
                setActivityLogData(res.data.data[0].client_facing_url_activity_log)
                setUrlDetailsData(res.data.data[0])
                let userDetails = res.data.data[0]
                if (userDetails && !userDetails.client_facing_url_token_deleted) {
                    setSaveFlag(false);
                }
                getClientFacingUrlDataAPI(profileId,
                    (res) => {
                        setActivityLogData(res.data.data[0].client_facing_url_activity_log)
                        setUrlDetailsData(res.data.data[0])
                        let userDetails = res.data.data[0]
                        if (userDetails && !userDetails.client_facing_url_token_deleted) {
                            setSaveFlag(false);
                        }
                        UpdateLoader(false)
                        handleClose()
                        setMessage({
                            status: true,
                            message: "URL Regenerated Successfully",
                            error: false,
                        })
                    },
                    (err) => {
                        setMessage({
                            status: true,
                            message: "Something Went Wrong!",
                            error: false,
                        })
                    })
                UpdateLoader(false)
            }
            else {
                setMessage({
                    status: true,
                    message: res.data.error.message,
                    error: true,
                })
            }
        },
            (err) => {
                console.log(err)
                UpdateLoader(false)
                setMessage({
                    status: true,
                    message: "Something Went Wrong!",
                    error: true,
                })
            })
    }

    return (
        <>
            <Dialog
                open={true}
                onClose={props.handleClose}
                aria-labelledby="responsive-dialog-title">
                <DialogContent>
                    <Grid item xs={12}>
                        <Grid container className="avl-email-link-popup" justifyContent="center">
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container className="avl-email-link-popup-title-container">
                                            <Typography id="avl-email-link-popup-title">Share Client Profile</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Grid container className="avl-email-link-popup-close-btn-container">
                                            <Box id="avl-email-popup-close-btn"><CloseIcon onClick={props.handleClose} /></Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container className="avl-email-url-activity-container" >
                                            <Grid item xl={3} lg={3} md={3} sm={3} xs={5.5} className="avl-email-url-item">
                                                <Button variant="text" size="small" id={urlDetails ? "avl-email-url-label" : "avl-email-activity-label"} onClick={() => {
                                                    setUrlDetails(true);
                                                    setActivityLog(false)
                                                }}>URL Details</Button>
                                            </Grid>
                                            <Grid item sx={{ height: "30px", borderRight: "1px solid black", alignItems: "end", justifyContent: "center" }}></Grid>
                                            <Grid item xl={3} lg={3} md={3} sm={3} xs={5.5} className="avl-email-activity-item"  >
                                                <Button variant="text" size="small" id={(!urlDetails) ? "avl-email-url-label" : "avl-email-activity-label"} onClick={() => {
                                                    setUrlDetails(false)
                                                    setActivityLog(true)
                                                }}>Activity Log</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {urlDetails === true ?
                                <>
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Grid container className="avl-email-link-popup" justifyContent="center">

                                            <Grid item xl={7} lg={7} md={7} sm={7} xs={12} >
                                                <Grid container className="avl-email-field-popup-container">
                                                    <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
                                                        <Grid container className="avl-email-popup-label">
                                                            <Typography id="avl-email-popup-label">Email Address</Typography>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                        <Grid container>
                                                            <TextField
                                                                fullWidth
                                                                className="avl-email-popup-textfield"
                                                                type="email"
                                                                id="outlined-size-small"
                                                                size="small"
                                                                onChange={(e) => {
                                                                    setChangeEmail(e.target.value)
                                                                }}
                                                                value={changeEmail}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xl={7} lg={7} md={7} sm={7} xs={12}>
                                                {urlDetailsData?.client_facing_url_token &&
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Grid container className="avl-email-popup-label">
                                                                <Typography id="avl-email-popup-label">URL
                                                                    {
                                                                        (urlDetailsData.client_facing_url_token_deleted) ?
                                                                            (
                                                                                <>
                                                                                    <span className="colorRed"> (Deleted on <Moment format="MM-DD-YYYY">{urlDetailsData.client_facing_url_token_deleted_timestamp}</Moment>)</span>
                                                                                </>
                                                                            ) : (<>
                                                                                <span className={(urlDetailsData.client_facing_url_expire) && "colorRed"}>  ({urlDetailsData.client_facing_url_expire ? "Expired on" : "Expires on"}  <Moment format="MM-DD-YYYY">{urlDetailsData.client_facing_url_token_expiry_date}</Moment>)</span>
                                                                            </>
                                                                            )
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container className={(urlDetailsData.client_facing_url_token_deleted) && "notAllowedCls"}>
                                                                <Grid item xs={10}>
                                                                    <TextField
                                                                        className={(urlDetailsData.client_facing_url_token_deleted || urlDetailsData.client_facing_url_expire) ? "avl-email-url-popup-textfield cursor-nodrop" : "avl-email-url-popup-textfield"}
                                                                        type="text"
                                                                        id="outlined-size-small"
                                                                        size="small"
                                                                        disabled={(urlDetailsData.client_facing_url_token_deleted || urlDetailsData.client_facing_url_expire) ? true : false}
                                                                        value={(urlDetailsData?.client_facing_url_token) ? ("https://" + state.user_detail.company_name.toLowerCase() + ".retirementbrain.io/profile/calculators?token=" + urlDetailsData?.client_facing_url_token + "&profile_id=" + props.data?.inputData?.profile_id) : ("")}
                                                                        onClick={() => {
                                                                            if (!urlDetailsData.client_facing_url_token_deleted && !urlDetailsData.client_facing_url_expire) {
                                                                                (window.open("https://" + state.user_detail.company_name.toLowerCase() + ".retirementbrain.io/profile/calculators?token=" + urlDetailsData?.client_facing_url_token + "&profile_id=" + props.data?.inputData?.profile_id, "_blank"))
                                                                            }
                                                                        }
                                                                        }
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={2} className="link-copy-icon-item">
                                                                    <TextField
                                                                        className={(urlDetailsData.client_facing_url_token_deleted || urlDetailsData.client_facing_url_expire) ? "avl-email-copy-url-popup-textfield cursor-nodrop" : "avl-email-copy-url-popup-textfield"}
                                                                        type="text"
                                                                        id="outlined-size-small"
                                                                        size="small"
                                                                        value=""
                                                                        disabled={(urlDetailsData.client_facing_url_token_deleted || urlDetailsData.client_facing_url_expire ? true : false)}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end" id="avl-copy-url-icon"
                                                                                    onClick={async (e) => {
                                                                                        if (!urlDetailsData.client_facing_url_token_deleted && !urlDetailsData.client_facing_url_expire) {

                                                                                            let url = "https://" + state.user_detail.company_name + ".retirementbrain.io/profile/calculators?token=" + urlDetailsData?.client_facing_url_token + "&profile_id=" + props.data?.inputData?.profile_id
                                                                                            await navigator.clipboard.writeText(url);
                                                                                            setMessage({
                                                                                                status: true,
                                                                                                message: "Copied!",
                                                                                                error: "",
                                                                                            })

                                                                                        }
                                                                                    }}>
                                                                                    <ContentCopyIcon />
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                }
                                            </Grid>

                                            <Grid item xl={7} lg={7} md={7} sm={7} xs={12} >
                                                <Grid container className="avl-save-email-popup-container">
                                                    {
                                                        (urlDetailsData.client_facing_url_token) ?
                                                            (
                                                                (urlDetailsData.client_facing_url_token_deleted) ?
                                                                    (!urlDetailsData.user_sample_profile_flag) ?
                                                                        (
                                                                            <>
                                                                                <Grid item xs={5.6}>
                                                                                    <Grid container className="avl-email-popup-btn">
                                                                                        <Button variant="outlined" className="scp-regenrate-btn"
                                                                                            onClick={
                                                                                                () => {
                                                                                                    setFlagRegenrateUrlPopup(true)

                                                                                                }}
                                                                                        >Regenerate</Button>
                                                                                    </Grid>
                                                                                </Grid>

                                                                                <Grid item xs={5.6}>
                                                                                    <Grid container className="avl-save-popup-btn">
                                                                                        <Button variant="contained" id="avl-email-save-popup-btn" onClick={() => { props.handleClose() }}>Close</Button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <Grid item xs={5.6}>
                                                                                    <Grid container className="avl-save-popup-btn">
                                                                                        <Button variant="outlined" id="avl-email-save-popup-btn" onClick={() => { props.handleClose() }}>Close</Button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </>
                                                                        )

                                                                    : (
                                                                        (urlDetailsData.client_facing_url_expire) ? (

                                                                            (!urlDetailsData.user_sample_profile_flag) ?
                                                                                (
                                                                                    <>
                                                                                        <Grid item xs={5.6}>
                                                                                            <Grid container className="avl-email-popup-btn">
                                                                                                <Button
                                                                                                    variant="outlined"
                                                                                                    color="error"
                                                                                                    id="avl-email-save-popup-btn"
                                                                                                    onClick={() => {
                                                                                                        setFlagDeleteLinkPopup(true)
                                                                                                    }}
                                                                                                >
                                                                                                    Delete
                                                                                                </Button>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xs={5.6}>
                                                                                            <Grid container className="avl-save-popup-btn">
                                                                                                <Button
                                                                                                    variant="contained"
                                                                                                    id="avl-email-save-popup-btn"
                                                                                                    onClick={() => { setFlagExtendUrlPopup(true) }}
                                                                                                >
                                                                                                    Extend URL
                                                                                                </Button>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <Grid item xs={5.6}>
                                                                                            <Grid container className="avl-save-popup-btn">
                                                                                                <Button variant="outlined" id="avl-email-save-popup-btn" onClick={() => { props.handleClose() }}>Close</Button>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </>
                                                                                )

                                                                        ) : (
                                                                            (!urlDetailsData.user_sample_profile_flag) ?
                                                                                (
                                                                                    <>
                                                                                        <Grid item xs={5.6}>
                                                                                            <Grid container className="avl-email-popup-btn">
                                                                                                <Button
                                                                                                    variant="outlined"
                                                                                                    color="error"
                                                                                                    id="avl-email-save-popup-btn"
                                                                                                    onClick={() => {
                                                                                                        setFlagDeleteLinkPopup(true)
                                                                                                    }}
                                                                                                >Delete</Button>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xs={5.6}>
                                                                                            <Grid container className="avl-email-popup-btn" >
                                                                                                <Button
                                                                                                    variant="contained"
                                                                                                    id="avl-email-save-popup-btn"
                                                                                                    onClick={() => { sendEmail(profileId) }}
                                                                                                >
                                                                                                    Send Email
                                                                                                </Button>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <Grid item xs={5.6}>
                                                                                            <Grid container className="avl-save-popup-btn">
                                                                                                <Button variant="contained" id="avl-email-save-popup-btn" onClick={() => { props.handleClose() }}>Close</Button>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xs={5.6}>
                                                                                            <Grid container className="avl-email-popup-btn" >
                                                                                                <Button
                                                                                                    variant="contained"
                                                                                                    id="avl-email-save-popup-btn"
                                                                                                    onClick={() => { sendEmail(profileId) }}
                                                                                                >
                                                                                                    Send Email
                                                                                                </Button>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </>
                                                                                )

                                                                        )
                                                                    )
                                                            )
                                                            : (
                                                                <>
                                                                    <Grid item xs={5.6}>
                                                                        <Grid container className="avl-email-popup-btn">
                                                                            <Button variant="contained" id="avl-email-save-popup-btn" onClick={() => { setSaveFlag(false) }}>Save</Button>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item xs={5.6}>
                                                                        <Grid container className="avl-save-popup-btn">
                                                                            <Button variant="contained" id="avl-email-save-popup-btn" onClick={() => { setSaveFlag(true) }} >Save & Email</Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>

                                                            )
                                                    }


                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </> : null}

                            {activityLog === true ?
                                <>
                                    <Grid container sx={{ marginTop: "30px" }}>
                                        <Grid item xs={12}>
                                            <TableContainer component={Paper} sx={{ minHeight: "18vh", maxHeight: "25vh", }}>
                                                <Table sx={{ minWidth: 200, }} aria-label="customized table">
                                                    <TableHead>
                                                        <TableRow className="activity-log-row-header">
                                                            <StyledTableCell className="share-data-time">Date & Time</StyledTableCell>
                                                            <StyledTableCell className="share-activity" align="left">Activity</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody className="table-row-padding"  >
                                                        {activityLogData?.map((row) => (

                                                            <StyledTableRow key={row.name} className="activity-log"   >
                                                                <StyledTableCell component="th" scope="row" className="date-time-coloumn"><Moment format="MM-DD-YYYY HH:mm:ss">{row.timestamp}</Moment></StyledTableCell>
                                                                <StyledTableCell align="left">{row.message}</StyledTableCell>
                                                            </StyledTableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </> : null}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            {flagDeleteLinkPopup &&
                <>
                    <DeleteLinkPopup
                        searchParams={searchParams}
                        urlDetailsData={urlDetailsData}
                        setUrlDetailsData={setUrlDetailsData}
                        updateSomething={updateSomething}
                        setUpdateSomething={setUpdateSomething}
                        setActivityLogData={setActivityLogData}
                        setSaveFlag={setSaveFlag}
                        handleClose={handleClose}
                        setMessage={setMessage}
                        message={message}
                    />
                </>
            }

            {flagPreSharePopup &&
                <>
                    <Dialog
                        open={true}
                        onClose={props.handleClose}
                        aria-labelledby="responsive-dialog-title"
                        maxWidth="xs"
                    >
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container className='clienturl-main-container'>
                                        <Grid item xs={12} className="cm-warning-alert-item">
                                            <span className="cm-warning-alert-icon"><img src={Warningmark} sx={{ color: "yellow" }} onClick={() => { handleClose() }} /></span>
                                        </Grid>

                                        <Grid item xs={12} className="cm-label-text" >
                                            <span className='cm-label-label'>
                                                Your client-facing URL has been deleted. If you want to regenrate URL then please click on continue.
                                            </span>
                                        </Grid>
                                        <Grid item xs={8} className="cm-continue-cancle-main-item">
                                            <Grid container className="cm-continue-cancle-main-container">
                                                <Grid item xs={5.5} className="cm-close-item">
                                                    <Button
                                                        variant='outlined'
                                                        className='cm-close-btn'
                                                        fullWidth
                                                        onClick={() => { handleClose() }}
                                                    >Cancel</Button>
                                                </Grid>
                                                <Grid item xs={5.5} className="cm-continue-item">
                                                    <Button
                                                        variant='contained'
                                                        className='cm-continue-btn'
                                                        fullWidth
                                                        onClick={() => { regenerateShareURL(profileId) }}
                                                    >Continue</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>

                </>
            }

            {flagRegenrateUrlPopup &&
                <>
                    <Dialog
                        open={true}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                        maxWidth="xs"
                    >
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container className='clienturl-main-container'>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid item xs={12} className="cm-head-item">
                                                        <span className="cm-heading-span">Regenerate URL Confirmation</span>
                                                    </Grid>
                                                </Grid>

                                                <Grid item>
                                                    <Grid container className="avl-email-link-popup-close-btn-container">
                                                        <span id="avl-email-popup-close-btn"><CloseIcon onClick={handleClose} /></span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} className="cm-label-text" >
                                            <span className='cm-label-label'>
                                                Are you sure you want to Regenerate URL?
                                            </span>
                                        </Grid>

                                        <Grid item xs={8} className="cm-continue-cancle-main-item">
                                            <Grid container className="cm-continue-cancle-main-container">
                                                <Grid item xs={5.5} className="cm-close-item">
                                                    <Button
                                                        variant='outlined'
                                                        className='cm-close-btn'
                                                        fullWidth
                                                        onClick={handleClose}
                                                    >Close</Button>
                                                </Grid>
                                                <Grid item xs={5.5} className="cm-continue-item">
                                                    <Button
                                                        variant='contained'
                                                        className='cm-continue-btn'
                                                        fullWidth
                                                        onClick={() => { regenerateUrl(profileId) }}
                                                    >Continue</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </>
            }

            {flagExtendUrlPopup &&
                <>
                    <Dialog
                        open={true}
                        onClose={props.handleClose}
                        aria-labelledby="responsive-dialog-title"
                        maxWidth="xs"
                    >
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container className='clienturl-main-container'>


                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid item xs={12} className="cm-head-item">
                                                        <span className="cm-heading-span">Extend URL Confirmation</span>
                                                    </Grid>

                                                </Grid>

                                                <Grid item>
                                                    <Grid container className="avl-email-link-popup-close-btn-container">
                                                        <span id="avl-email-popup-close-btn"><CloseIcon onClick={() => { handleClose() }} /></span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>


                                        <Grid item xs={12} className="cm-label-text" >
                                            <span className='cm-label-label'>
                                                Are you sure you want to extend link?
                                            </span>
                                        </Grid>

                                        <Grid item xs={8} className="cm-continue-cancle-main-item">
                                            <Grid container className="cm-continue-cancle-main-container">
                                                <Grid item xs={5.5} className="cm-close-item">
                                                    <Button
                                                        variant='outlined'
                                                        className='cm-close-btn'
                                                        fullWidth
                                                        onClick={() => { handleClose() }}
                                                    >Close</Button>
                                                </Grid>
                                                <Grid item xs={5.5} className="cm-continue-item">
                                                    <Button
                                                        variant='contained'
                                                        className='cm-continue-btn'
                                                        fullWidth
                                                        onClick={() => { extendLinkUrl(profileId) }}
                                                    >Continue</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </>
            }




            {message.status &&
                <Snackbar open={true} autoHideDuration={6000} onClose={handleClose1}>
                    <Alert onClose={handleClose1} severity={message.error == true ? "error" : "success"} sx={{ width: '100%' }}>
                        {message.message}
                    </Alert>
                </Snackbar>
            }

            {/* <ClientPageUrlSavePopup clientPageUrlSavePopup={clientPageUrlSavePopup} handleClose={handleClose} handleOpen={handleOpen} />
            <ClientPageUrlSaveEmailPopup clientPageUrlSaveEmailPopup={clientPageUrlSaveEmailPopup} handleClose={handleClose} handleOpen={handleOpen} /> */}
        </>

    )
}


export default ShareClientProfilePopup;