import React, { useContext, useState } from 'react'
import { Avatar, Button, Divider, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Header from "../../GlobalComponent/Header/Header";
import { useNavigate } from "react-router-dom";
import ShareIcon from '@mui/icons-material/Share';
import CreateIcon from '@mui/icons-material/Create';
import ShareClientProfilePopup from "../../GlobalComponent/ShareClientProfilePopup/ShareClientProfilePopup";
import { Context as mainContext } from '../../Context/auth_context'
import ConfirmMessagePopup from '../../GlobalComponent/ConfirmMessagePopup/DeleteLinkPopup';
import ShareDeletePopup from '../../GlobalComponent/ShareDeletePopup/ShareDeletePopup';
import '../OutputAction/OutputAction.css'

const OutputAction = (props) => {
    const [shareClientProfilePopup, setShareClientProfilePopup] = useState(false)
    const [deleteLinkPopup, setDeleteLinkPopup] = useState(false)


    const { state } = useContext(mainContext)
    const navigate = useNavigate()

    const handleClose = () => {
        setShareClientProfilePopup(false);
    }

    const handleOpen = () => {
        setShareClientProfilePopup(true);
    }

    return (
        <>
            {shareClientProfilePopup &&
                <ShareClientProfilePopup
                    data={props}
                    handleClose={handleClose}
                    setDeleteLinkPopup={setDeleteLinkPopup}
                    deleteLinkPopup={deleteLinkPopup}

                />
            }

            {deleteLinkPopup &&
                <ConfirmMessagePopup
                    data={props}
                    setDeleteLinkPopup={setDeleteLinkPopup}
                />
            }

            {
                (state.user_detail.loggedIn) &&
                (
                    <Grid container className="fee-share-edit-btn-container" direction="column">
                        <Grid item  >
                            <Avatar sx={{ backgroundColor: "#3B78CE", width: 35, height: 35 }} className="fee-edit-btn-box">
                                <CreateIcon onClick={(e) => { navigate("/userprofile?profile_id=" + props.inputData.profile_id) }} />
                            </Avatar>
                        </Grid>
                        <Grid item >
                            <Avatar sx={{ backgroundColor: "#3B78CE", width: 35, height: 35 }} className="fee-edit-share-btn-box">
                                <ShareIcon
                                    className={props.data.status == false ? "disableIcon" : ""}
                                    onClick={(e) => { props.data.status !== false && handleOpen() }} />
                            </Avatar>
                        </Grid>
                    </Grid>
                )
            }


        </>
    )
}

export default OutputAction;