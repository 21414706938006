import { Button, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import Header from '../../GlobalComponent/Header/Header';
import './PricingPage.css'
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/system';
import DoneIcon from '@mui/icons-material/Done';
import { useState } from 'react';
import Footer from '../../GlobalComponent/Footer/Footer';
// import './PricingPage.scss'
// import PricingPageContainer from './PricingPageContainer';

const PricingPage = () => {

    // const { objProp } = props;
    // const { level, applyGradient, price, para1, para2, btnDark, tick } = objProp;
    const [pricestate, setPricestate] = useState([
        {
            level: "FREE",
            applyGradient: "blueGradient",
            price: "/month",
            para1: "Great for Staters",
            para2: "Discover how to create your first projects.",
            btnDark: false,
            tick: false,
        },
        {
            level1: "LITE",
            applyGradient: "purpleGradient",
            price: 7,
            para1: "For Planned Projects",
            para2: "Bring your designs to the next level and export them.",
            btnDark: true,
            tick: true,
        },
        {
            level2: "PRO",
            applyGradient: "orangeGradient",
            price: 12,
            para1: "For Professional Use",
            para2: "Enjoy limitless use with interactive export options",
            btnDark: false,
            tick: true,
        },
    ])

    useEffect(() => {
        setPricestate(
            {
                level: "FREE",
                applyGradient: "blueGradient",
                price: "$0/month",
                para1: "Great for Staters",
                para2: "Discover how to create your first projects.",
                btnDark: false,
                tick: false,
            },
            {
                level1: "LITE",
                applyGradient: "purpleGradient",
                price1: 7,
                para1: "For Planned Projects",
                para2: "Bring your designs to the next level and export them.",
                btnDark: true,
                tick: true,
            },
            {
                level2: "PRO",
                applyGradient: "orangeGradient",
                price2: 12,
                para1: "For Professional Use",
                para2: "Enjoy limitless use with interactive export options",
                btnDark: false,
                tick: true,
            },
        )
    
      
    }, [pricestate])
    


    // const pricingDetail = () => [
    //     {
    //         level: "FREE",
    //         applyGradient: "blueGradient",
    //         price: 0,
    //         para1: "Great for Staters",
    //         para2: "Discover how to create your first projects.",
    //         btnDark: false,
    //         tick: false,
    //     },
    //     {
    //         level1: "LITE",
    //         applyGradient: "purpleGradient",
    //         price: 7,
    //         para1: "For Planned Projects",
    //         para2: "Bring your designs to the next level and export them.",
    //         btnDark: true,
    //         tick: true,
    //     },
    //     {
    //         level2: "PRO",
    //         applyGradient: "orangeGradient",
    //         price: 12,
    //         para1: "For Professional Use",
    //         para2: "Enjoy limitless use with interactive export options",
    //         btnDark: false,
    //         tick: true,
    //     },
    // ]
    // const {pricingDetailObj} = pricingDetail; 
    // const {level,applyGradient,price,pera1,pera2,btnDark,tick} = pricingDetailObj



    return(
        <>
            <Header />
            <Grid container>
                <Grid item xs={12}>
                    <Grid container className='pricing-page-main-container'>
                        <Grid item xs={12}>

                            <Grid container>
                            
                                <Grid item xs={10} className='pricing-page-item'>
                                    <Grid container className='pricing-page-container' >
                                        <Grid item xs={2} className='pricing-page-heading-item'>
                                            <span id="pricing-page-head-text">Pricing</span>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={10} className="pricing-page-cards-main-items">
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container>

                                                <Grid item xs={3} className="pricing-page-card-sub-item">
                                                    <Grid container>
                                                        <Grid item xs={11} className="pp-card-sub-items">
                                                            <Grid container >
                                                                <Grid item xs={12} >
                                                                    <span id="pp-card-label1">{pricestate.level}</span>
                                                                </Grid>

                                                                <Grid item xs={12} className="pp-card-item2">
                                                                    <span id="pp-card-label2">{pricestate.price}</span>
                                                                </Grid>

                                                                <Grid item xs={12} className="pp-card-item3">
                                                                    <span id="pp-card-lebel3">GREAT FOR STATERS</span>
                                                                </Grid>

                                                                <Grid item xs={12} className="pp-card-item4">
                                                                    <span id="pp-card-lebel4">Discover how to create your first projects.</span>
                                                                </Grid>

                                                                <Grid item xs={10} className="pp-card-item5">
                                                                    <Button variant='outlined' id="pp-card-btn" fullWidth>GET STARTED</Button>
                                                                </Grid>

                                                                <Grid item xs={12} >
                                                                    <Grid container justifyContent="center" >

                                                                        <Grid item xs={8}>
                                                                            <Grid container justifyContent="center">

                                                                                <Grid item xs={10} className="pp-card-plan-item">
                                                                                    <Stack direction="row" alignItems="center" >
                                                                                        <CloseIcon className='pp-close-icon' />
                                                                                        <span id="pp-card-plan-lebel">3 new projects/month</span >
                                                                                    </Stack>
                                                                                </Grid>

                                                                                <Grid item xs={10} className="pp-card-plan-item">
                                                                                    <Stack direction="row" alignItems="center" >
                                                                                        <CloseIcon className='pp-close-icon' />
                                                                                        <span id="pp-card-plan-lebel">Basic Interaction</span >
                                                                                    </Stack>
                                                                                </Grid>

                                                                                <Grid item xs={10} className="pp-card-plan-item">
                                                                                    <Stack direction="row" alignItems="center"  >
                                                                                        <DoneIcon className='pp-right-icon' />
                                                                                        <span id="pp-card-plan-lebel">Assets Library</span >
                                                                                    </Stack>
                                                                                </Grid>

                                                                            </Grid>
                                                                        </Grid>
                                                                        
                                                                    </Grid>
                                                                    
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={3} className="pricing-page-card-sub-item">
                                                    <Grid container>
                                                        <Grid item xs={11} className="pp-card-sub-lite-items">
                                                            <Grid container >
                                                                <Grid item xs={12} >
                                                                    <span id="pp-card-label1">LITE</span>
                                                                </Grid>

                                                                <Grid item xs={12} className="pp-card-item2">
                                                                    <span id="pp-card-label2">$25/mo</span>
                                                                </Grid>

                                                                <Grid item xs={12} className="pp-card-item3">
                                                                    <span id="pp-card-lebel3">GREAT FOR STATERS</span>
                                                                </Grid>

                                                                <Grid item xs={12} className="pp-card-item4">
                                                                    <span id="pp-card-lebel4">Discover how to create your first projects.</span>
                                                                </Grid>

                                                                <Grid item xs={10} className="pp-card-item5">
                                                                    <Button variant='outlined' id="pp-card-btn" fullWidth>GET STARTED</Button>
                                                                </Grid>

                                                                <Grid item xs={12} >
                                                                    <Grid container justifyContent="center" >

                                                                        <Grid item xs={8}>
                                                                            <Grid container justifyContent="center">

                                                                                <Grid item xs={10} className="pp-card-plan-item">
                                                                                    <Stack direction="row" alignItems="center" >
                                                                                        <CloseIcon className='pp-close-icon' />
                                                                                        <span id="pp-card-plan-lebel">3 new projects/month</span >
                                                                                    </Stack>
                                                                                </Grid>

                                                                                <Grid item xs={10} className="pp-card-plan-item">
                                                                                    <Stack direction="row" alignItems="center" >
                                                                                        <DoneIcon className='pp-right-icon' />
                                                                                        <span id="pp-card-plan-lebel">Basic Interaction</span >
                                                                                    </Stack>
                                                                                </Grid>

                                                                                <Grid item xs={10} className="pp-card-plan-item">
                                                                                    <Stack direction="row" alignItems="center"  >
                                                                                        <DoneIcon className='pp-right-icon' />
                                                                                        <span id="pp-card-plan-lebel">Assets Library</span >
                                                                                    </Stack>
                                                                                </Grid>

                                                                            </Grid>
                                                                        </Grid>
                                                                        
                                                                    </Grid>
                                                                    
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={3} className="pricing-page-card-sub-item">
                                                    <Grid container>
                                                        <Grid item xs={11} className="pp-card-sub-pro-items">
                                                            <Grid container >
                                                                <Grid item xs={12} >
                                                                    <span id="pp-card-label1">PRO</span>
                                                                </Grid>

                                                                <Grid item xs={12} className="pp-card-item2">
                                                                    <span id="pp-card-label2">$50/month</span>
                                                                </Grid>

                                                                <Grid item xs={12} className="pp-card-item3">
                                                                    <span id="pp-card-lebel3">GREAT FOR STATERS</span>
                                                                </Grid>

                                                                <Grid item xs={12} className="pp-card-item4">
                                                                    <span id="pp-card-lebel4">Discover how to create your first projects.</span>
                                                                </Grid>

                                                                <Grid item xs={10} className="pp-card-item5">
                                                                    <Button variant='outlined' id="pp-card-btn" fullWidth>GET STARTED</Button>
                                                                </Grid>

                                                                <Grid item xs={12} >
                                                                    <Grid container justifyContent="center" >

                                                                        <Grid item xs={8}>
                                                                            <Grid container justifyContent="center">

                                                                                <Grid item xs={10} className="pp-card-plan-item">
                                                                                    <Stack direction="row" alignItems="center" >
                                                                                        <DoneIcon className='pp-right-icon' />
                                                                                        <span id="pp-card-plan-lebel">3 new projects/month</span >
                                                                                    </Stack>
                                                                                </Grid>

                                                                                <Grid item xs={10} className="pp-card-plan-item">
                                                                                    <Stack direction="row" alignItems="center" >
                                                                                        <DoneIcon className='pp-right-icon' />
                                                                                        <span id="pp-card-plan-lebel">Basic Interaction</span >
                                                                                    </Stack>
                                                                                </Grid>

                                                                                <Grid item xs={10} className="pp-card-plan-item">
                                                                                    <Stack direction="row" alignItems="center"  >
                                                                                        <DoneIcon className='pp-right-icon' />
                                                                                        <span id="pp-card-plan-lebel">Assets Library</span >
                                                                                    </Stack>
                                                                                </Grid>

                                                                            </Grid>
                                                                        </Grid>
                                                                        
                                                                    </Grid>
                                                                    
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        </>
    )
}

export default PricingPage;