import React, { Fragment, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { Provider as AuthProvider } from './Context/auth_context'
import RoutePage from './Pages/RoutePage'

function App() {
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          {/* <Routes> */}
          <Fragment>
            <RoutePage />
          </Fragment>
          {/* </Routes> */}
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
