import { Alert, AlertTitle, Avatar, Button, Dialog, Divider, Grid, Menu, MenuItem, Snackbar, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import Header from "../../GlobalComponent/Header/Header";
// import './UPTaxes.css';
import UPFee from "../../Pages/UPFeePage/UPFee";
import UPRetirementIncome from "../../Pages/UPRetirementIncomePage/UPRetirementIncome";
import UPTaxes from "../../Pages/UPTaxesPage/UPTaxes";
import UPAccountValue from "../../Pages/UPAccountValuePage/UPAccountValue";
import UPSPComparison from "../../Pages/UPSPComparisonPage/UPSPComparison";
import { Context as mainContext } from '../../Context/auth_context'
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getparticularUserProfileDetail, getparticularUserProfileDetailPublic } from '../../service/login.service'
import ShareClientProfilePopup from "../ShareClientProfilePopup/ShareClientProfilePopup";
import "./Modules.css"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Fade from '@mui/material/Fade';
import OutputAction from "../../Pages/OutputAction/OutputAction";
import Link from '@mui/material/Link';
import CreateIcon from '@mui/icons-material/Create';
import ShareIcon from '@mui/icons-material/Share';



const Modules = (props) => {
    const { state, updateCalculationHistory } = useContext(mainContext)
    const [showUPFeePage, setShowUPFeePage] = useState(false)
    const [setCalculationId, setSetCalculationId] = useState(false)
    const [showUPTaxesPage, setShowUPTaxesPage] = useState(false)
    const [showUPRetirementIncomePage, showSetUPRetirementIncomePage] = useState(false)
    const [showUPAccountValuePage, showSetUPAccountValuePage] = useState(false)
    const [showUPSPComparisonPage, showSetUPSPComparisonPage] = useState(true)
    const [searchParams, setSearchParams] = useSearchParams();
    const [calculatorData, setCalculatorData] = useState([{}])
    const [inputData, setinputData] = useState({})
    const [publicUrl, setPublicUrl] = useState(false)
    const [timeButton, setTimeButton] = useState("")

    const [shareClientProfilePopup, setShareClientProfilePopup] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open1 = Boolean(anchorEl);
    const [open, setOpen] = React.useState();
    const [moduleName, setModuleName] = useState("S & P comparison")
    const [showAlertMessage, setShowAlertMessage] = React.useState({
        status: false,
        message: "",
        error: false,
    });
    const [responseStatus, setResponseStatus] = useState([{}])


    const handleClose = () => {
        setShareClientProfilePopup(false);
    }


    const handleOpen = () => {
        setShareClientProfilePopup(true);
    }


    useEffect(() => {
        let id = searchParams.get("id");
        let token = searchParams.get("token");
        let profile_id = searchParams.get("profile_id");
        if (token && profile_id) {
            let obj = {
                token: token,
                profile_id: profile_id,
            }
            getparticularUserProfileDetailPublic(obj, (res) => {
                if (!res.data.status) {
                    return navigate('/login')
                }

                setPublicUrl(true)
                setCalculatorData(res.data.data[0])
                setinputData(res.data.inputData[0])
                setSetCalculationId(id)
            }, (err) => {
                console.log("getparticularUserProfileDetail error")
                console.log(err)
                navigate('/login')
            })
        } else {
            getparticularUserProfileDetail(id,
                (res) => {
                    if (res.data.data[0].status == false) {
                        if (res.data.data[0].error.code == 9038) {
                            setShowAlertMessage({
                                status: true,
                                error: true,
                                message: res.data.data[0].error.message.replace("Profile is saved successfully but there is error in the input data. ", "")
                            })
                            setCalculatorData(res.data.data[0])
                            setinputData(res.data.inputData[0])
                            setSetCalculationId(id)
                            setResponseStatus(res.data.data[0].status)
                        }
                        else {
                            setShowAlertMessage({
                                status: true,
                                error: true,
                                message: res.data.data[0].error.message
                            })
                            setCalculatorData(res.data.data[0])
                            setinputData(res.data.inputData[0])
                            setSetCalculationId(id)
                            setResponseStatus(res.data.data[0].status)

                        }
                    }
                    else {
                        setCalculatorData(res.data.data[0])
                        setinputData(res.data.inputData[0])
                        setSetCalculationId(id)
                        setResponseStatus(res.data.data[0].status)

                    }
                }, (err) => {
                    console.log("getparticularUserProfileDetail error")
                    console.log(err)
                })
        }
        setTimeout(function () {
            if (state?.calculationHistory) {
                showSetUPSPComparisonPage(false)
                setShowUPFeePage(false)
                setShowUPTaxesPage(false)
                showSetUPRetirementIncomePage(false)
                showSetUPAccountValuePage(false)
                switch (state?.calculationHistory) {
                    case 0:
                        showSetUPSPComparisonPage(true)
                        break
                    case 1:
                        showSetUPAccountValuePage(true)
                        break
                    case 2:
                        showSetUPRetirementIncomePage(true)
                        break
                    case 3:
                        setShowUPTaxesPage(true)
                        break
                    case 4:
                        setShowUPFeePage(true)
                        break
                    default:
                        showSetUPSPComparisonPage(true)
                        break

                }
            }
        }, 1000)

    }, [])

    const navigate = useNavigate();



    const openUPFeePage = () => {
        updateCalculationHistory(4)
        setShowUPFeePage(true)
        setShowUPTaxesPage(false)
        showSetUPRetirementIncomePage(false)
        showSetUPAccountValuePage(false)
        showSetUPSPComparisonPage(false)
    }

    const openUPTaxesPage = () => {
        updateCalculationHistory(3)
        setShowUPFeePage(false)
        setShowUPTaxesPage(true)
        showSetUPRetirementIncomePage(false)
        showSetUPAccountValuePage(false)
        showSetUPSPComparisonPage(false)
    }

    const openUPRetirementIncomePage = () => {
        updateCalculationHistory(2)
        setShowUPFeePage(false)
        setShowUPTaxesPage(false)
        showSetUPRetirementIncomePage(true)
        showSetUPAccountValuePage(false)
        showSetUPSPComparisonPage(false)
    }

    const openUPAccountValuePage = () => {
        updateCalculationHistory(1)
        setShowUPFeePage(false)
        setShowUPTaxesPage(false)
        showSetUPRetirementIncomePage(false)
        showSetUPAccountValuePage(true)
        showSetUPSPComparisonPage(false)
    }

    const openUPSPComparisonPage = () => {
        updateCalculationHistory(0)
        setShowUPFeePage(false)
        setShowUPTaxesPage(false)
        showSetUPRetirementIncomePage(false)
        showSetUPAccountValuePage(false)
        showSetUPSPComparisonPage(true)
    }


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleOptionClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Header />
            {window.screen.width > 900 &&
                <>

                    <Grid container className="up-main-container" >
                        <Grid item xs={11} md={11.8}>
                            <Grid container className="up-sub-container"  >


                                {/* main content */}
                                <Grid item xs={12} md={12} className="up-blue-item">
                                    <Grid container className="up-blue-container">

                                        {showAlertMessage.status &&
                                            <Grid item xl={12} xs={12} className='invalid-data-alert-item' >
                                                <Alert
                                                    className="up-alert-item"
                                                    severity={showAlertMessage.error == true ? "error" : "success"}
                                                    sx={{ width: '100%' }}
                                                >
                                                    <AlertTitle
                                                        sx={{ fontSize: "15px", fontWeight: "600", margin: "0px" }}
                                                    >Error</AlertTitle>
                                                    <span className="alert-message-span" > {showAlertMessage?.message ? showAlertMessage?.message?.replace(/Error! /gi, "") : ""}</span>

                                                    <Link
                                                        className="up-module-alert-link"
                                                        onClick={(e) => { navigate("/userprofile?profile_id=" + inputData.profile_id) }}
                                                    >Update Profile</Link>
                                                </Alert>
                                            </Grid>
                                        }

                                        <Grid item xs={12} className='upsp-module-md-scree-title'>
                                            <span className='upsp-module-md-screen-text'>{inputData?.universalInput?.firstName}</span>
                                        </Grid>


                                        {/* button */}
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                            {/* <Grid container className="up-white-container1"> */}
                                            <Grid container className={showAlertMessage.status == true ? "up-white-container1" : "up-white-container1"}>

                                                <Grid item xl={1.5} lg={1.5} md={1.5} xs={1.5} className="up-container1-user-btn" >
                                                    <Typography id="up-container1-user-btn">{inputData?.universalInput?.firstName}</Typography>
                                                </Grid>

                                                <Grid item xl={10.5} lg={10} md={10.5} xs={10.5} >
                                                    <Grid container>
                                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                            <Grid container>
                                                                <Grid item xl={2.4} lg={2.4} md={2.2} xs={2.4}>
                                                                    <Grid container className="up-container1-btn">
                                                                        <Button href="" id={showUPSPComparisonPage == true ? "up-container1-round-btn" : "up-container1-btn"} onClick={openUPSPComparisonPage} >S&P Comparison</Button>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xl={2.4} lg={2.4} md={2.4} xs={2.4}>
                                                                    <Grid container className="up-container1-btn" >
                                                                        <Button href="" id={showUPAccountValuePage == true ? "up-container1-round-btn" : "up-container1-btn"} onClick={openUPAccountValuePage}>Account Value</Button>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xl={2.4} lg={2.4} md={2.6} xs={2.4}>
                                                                    <Grid container className="up-container1-btn">
                                                                        <Button href="" id={showUPRetirementIncomePage == true ? "up-container1-round-btn" : "up-container1-btn"} onClick={openUPRetirementIncomePage}>Retirement Income</Button>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xl={2.4} lg={2.4} md={2.4} xs={2.4}>
                                                                    <Grid container className="up-container1-btn">
                                                                        <Button href="" id={showUPTaxesPage == true ? "up-container1-round-btn" : "up-container1-btn"} onClick={openUPTaxesPage}>Taxes</Button>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xl={2.4} lg={2.4} md={2.4} xs={2.4}>
                                                                    <Grid container className="up-container1-btn">
                                                                        <Button href="" id={showUPFeePage == true ? "up-container1-round-btn" : "up-container1-btn"} onClick={openUPFeePage}>Fees</Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>



                                        {/* All Pages */}
                                        <Grid item xs={12}>
                                            <Grid container >
                                                {showUPFeePage && <UPFee data={calculatorData} inputData={inputData} />}
                                                {showUPTaxesPage && <UPTaxes data={calculatorData} inputData={inputData} />}
                                                {showUPRetirementIncomePage && <UPRetirementIncome data={calculatorData} inputData={inputData} />}
                                                {showUPAccountValuePage && <UPAccountValue data={calculatorData} inputData={inputData} />}
                                                {showUPSPComparisonPage && <UPSPComparison data={calculatorData} inputData={inputData} />}
                                            </Grid>
                                        </Grid>

                                        {!state.user_detail.loggedIn &&

                                            <Grid xs={12} className="module-home-pera">
                                                <Box className="link-Disclaimer-heading">Disclaimer</Box>
                                                <Box className="link-Disclaimer-text">This presentation is a general discussion of the topic presented, based on our current understanding of applicable tax laws, regulations,
                                                    and rulings. This information and presentation in no way should be interpreted as investment or tax advice. In actual practice, the transactions
                                                    discussed may be more complex and will require the attention and expertise of professional tax and legal advisors.
                                                    In order to comply with certain U.S. Treasury regulations, please be advised of the following: unless expressly stated otherwise, any reference to U.S. Federal tax information contained in
                                                    these materials, including any attachments, is not intended or written to be used, and cannot be used, by any person for the purpose of avoiding any penalties
                                                    that may be imposed by the Internal Revenue Service. Values and benefits are not guaranteed unless stated as guaranteed on illustrations. Examples are used
                                                    to illustrate the potential benefits of indexed universal life insurance / IRS SEC. 7702 & 72(e). Portions of this presentation are based on NAIC compliant
                                                    life insurance illustrations which must be present during the presentation and fully reviewed for additional disclosures.</Box>
                                            </Grid>
                                        }




                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>

                </>
            }
            {window.screen.width <= 900 && window.screen.width >= 320 &&
                <>
                    <Grid container className='md-menu-main-container'>
                        <Grid item sm={12} xs={12} >
                            <Grid container>

                                <Grid item sm={11} xs={12} className='md-menu-sub-item'>
                                    <Grid container className='md-menu-blue-container' >

                                        {showAlertMessage.status &&
                                            <Grid item xl={12} xs={12} className='invalid-data-alert-item' >
                                                <Alert
                                                    className="up-alert-item"
                                                    severity={showAlertMessage.error == true ? "error" : "success"}
                                                    sx={{ width: '100%' }}
                                                >
                                                    <AlertTitle
                                                        sx={{ fontSize: "15px", fontWeight: "600", margin: "0px" }}
                                                    >Error</AlertTitle>
                                                    <span className="alert-message-span" > {showAlertMessage.message.replace(/Error! /gi, "")}</span>

                                                    <Link
                                                        className="up-module-alert-link"
                                                        onClick={(e) => { navigate("/userprofile?profile_id=" + inputData.profile_id) }}
                                                    >Update Profile</Link>
                                                </Alert>
                                            </Grid>
                                        }

                                        <Grid item sm={12} xs={12} className='md-menu-blue-item'>
                                            <Grid container>

                                                <Grid item xs={3}></Grid>

                                                <Grid item sm={6} xs={6} className='md-menu-username-item'>
                                                    <span className="md-menu-username">{inputData?.universalInput?.firstName}</span>
                                                </Grid>

                                                <Grid item sm={3} xs={3} className='md-menu-three-dot-menu-item'>
                                                    <Button
                                                        id="fade-button"
                                                        aria-controls={open ? 'fade-menu' : undefined}
                                                        aria-haspopup="true"
                                                        aria-expanded={open ? 'true' : undefined}
                                                        // onClick={handleClick}
                                                        onClick={(e) => {
                                                            handleClick(e)

                                                        }}
                                                    >
                                                        <MoreVertIcon className="optionIcon" />
                                                    </Button>
                                                </Grid>

                                                <Grid item sm={12} xs={12}>
                                                    <Grid container className="md-menu-image-contanier">

                                                        <Grid item sm={12} xs={12} className='md-menu-head-item'>
                                                            <span className="md-menu-page-name-text">{moduleName}</span>
                                                        </Grid>

                                                        {shareClientProfilePopup && <ShareClientProfilePopup data={{ data: calculatorData, inputData: inputData }} handleClose={handleClose} />}

                                                        {(state.user_detail.loggedIn) &&

                                                            <Grid item sm={12} xs={12}>
                                                                <Grid container direction="row" className="md-menu-edit-share-btn">

                                                                    {moduleName == "S & P comparison" &&
                                                                        <Grid item sm={3} xs={4} className="md-upsp-time-period-btn">
                                                                            <Button variant="contained" className="md-upsp-time-btn" onClick={() => { timeButton == "time" ? setTimeButton("") : setTimeButton("time") }} fullWidth>Time Period</Button>
                                                                        </Grid>
                                                                    }
                                                                    <Grid item sm={2} xs={2} className='md-upsp-edit-btn-item'>
                                                                        <Avatar sx={{ backgroundColor: "#3B78CE", width: 35, height: 35, }} className='md-upsp-edit-avtar-item'>
                                                                            <CreateIcon onClick={(e) => { navigate("/userprofile?profile_id=" + setCalculationId) }} />
                                                                        </Avatar>
                                                                    </Grid>

                                                                    <Grid item sm={2} xs={2} >
                                                                        <Avatar sx={{ backgroundColor: "#3B78CE", width: 35, height: 35 }} className='md-upsp-edit-btn-item'>
                                                                            <ShareIcon
                                                                                className={responseStatus == false ? "disableIcon" : ""}
                                                                                onClick={() => { responseStatus !== false && handleOpen() }}

                                                                            />
                                                                        </Avatar>
                                                                    </Grid>

                                                                    {moduleName == "S & P comparison" &&
                                                                        <Grid item sm={3} xs={3} className="md-upsp-var-period-btn">
                                                                            <Button variant="contained" className="md-upsp-variable-btn" onClick={() => { timeButton == "variable" ? setTimeButton("") : setTimeButton("variable") }} fullWidth>Variables</Button>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        }

                                                        {/* <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item sm={12}>
                                                                <Grid container className='upsp-start-amt-main-container'>
                                                                    <Grid item sm={6} className='upsp-start-amt-item'>
                                                                        <Grid container className='upsp-start-amt-container'>
                                                                            <Grid item sm={6}>
                                                                                <span className='upsp-start-amt-text'>Start Amount</span>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item sm={6} className='upsp-start-amt-value-item'>
                                                                        <span className='upsp-start-amt-value-text'>2,00,000</span>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid> */}

                                                        <Grid item sm={12} xs={12}>
                                                            <Grid container>
                                                                {showUPFeePage && <UPFee data={calculatorData} inputData={inputData} />}
                                                                {showUPTaxesPage && <UPTaxes data={calculatorData} inputData={inputData} />}
                                                                {showUPRetirementIncomePage && <UPRetirementIncome data={calculatorData} inputData={inputData} />}
                                                                {showUPAccountValuePage && <UPAccountValue data={calculatorData} inputData={inputData} />}
                                                                {showUPSPComparisonPage && <UPSPComparison data={calculatorData} inputData={inputData} timeButton={timeButton} />}

                                                            </Grid>
                                                        </Grid>


                                                        {/* {!state.user_detail.loggedIn &&

                                                        <Grid xs={12} className="module-home-pera">
                                                            <Box className="link-Disclaimer-heading">Disclaimer</Box>
                                                            <Box className="link-Disclaimer-text">This presentation is a general discussion of the topic presented, based on our current understanding of applicable tax laws, regulations,
                                                                and rulings. This information and presentation in no way should be interpreted as investment or tax advice. In actual practice, the transactions
                                                                discussed may be more complex and will require the attention and expertise of professional tax and legal advisors.
                                                                In order to comply with certain U.S. Treasury regulations, please be advised of the following: unless expressly stated otherwise, any reference to U.S. Federal tax information contained in
                                                                these materials, including any attachments, is not intended or written to be used, and cannot be used, by any person for the purpose of avoiding any penalties
                                                                that may be imposed by the Internal Revenue Service. Values and benefits are not guaranteed unless stated as guaranteed on illustrations. Examples are used
                                                                to illustrate the potential benefits of indexed universal life insurance / IRS SEC. 7702 & 72(e). Portions of this presentation are based on NAIC compliant
                                                                life insurance illustrations which must be present during the presentation and fully reviewed for additional disclosures.</Box>
                                                        </Grid>
                                                    } */}


                                                    </Grid>
                                                </Grid>

                                                {!state.user_detail.loggedIn &&

                                                    <Grid xs={12} className="module-home-pera">
                                                        <Box className="link-Disclaimer-heading">Disclaimer</Box>
                                                        <Box className="link-Disclaimer-text">This presentation is a general discussion of the topic presented, based on our current understanding of applicable tax laws, regulations,
                                                            and rulings. This information and presentation in no way should be interpreted as investment or tax advice. In actual practice, the transactions
                                                            discussed may be more complex and will require the attention and expertise of professional tax and legal advisors.
                                                            In order to comply with certain U.S. Treasury regulations, please be advised of the following: unless expressly stated otherwise, any reference to U.S. Federal tax information contained in
                                                            these materials, including any attachments, is not intended or written to be used, and cannot be used, by any person for the purpose of avoiding any penalties
                                                            that may be imposed by the Internal Revenue Service. Values and benefits are not guaranteed unless stated as guaranteed on illustrations. Examples are used
                                                            to illustrate the potential benefits of indexed universal life insurance / IRS SEC. 7702 & 72(e). Portions of this presentation are based on NAIC compliant
                                                            life insurance illustrations which must be present during the presentation and fully reviewed for additional disclosures.</Box>
                                                    </Grid>
                                                }

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Menu

                        id="fade-menu"
                        MenuListProps={{
                            'aria-labelledby': 'fade-button',
                        }}
                        anchorEl={anchorEl}
                        open={open1}
                        onClose={handleOptionClose}
                        TransitionComponent={Fade}
                        className="agent-edit-dlt-menu"
                    >
                        <MenuItem className="optionsClass"
                            onClick={() => {
                                setOpen();
                                setAnchorEl(null);
                                openUPSPComparisonPage()
                                setModuleName("S & P comparison")

                            }}
                        >S & P comparison</MenuItem>
                        <MenuItem
                            className="optionsClass"
                            onClick={() => {
                                setAnchorEl(null);
                                openUPAccountValuePage()
                                setModuleName("Account Value")
                            }}
                        >
                            Account Value</MenuItem>

                        <MenuItem className="optionsClass"
                            onClick={() => {
                                setOpen();
                                setAnchorEl(null);
                                openUPRetirementIncomePage()
                                setModuleName("Retirement Income")
                            }}
                        >Retirement Income</MenuItem>

                        <MenuItem className="optionsClass"
                            onClick={() => {
                                setOpen();
                                setAnchorEl(null);
                                openUPTaxesPage()
                                setModuleName("Taxes")
                            }}
                        >Taxes</MenuItem>

                        <MenuItem className="optionsClass"
                            onClick={() => {
                                setOpen();
                                setAnchorEl(null);
                                openUPFeePage()
                                setModuleName("Fees")
                            }}
                        >Fees</MenuItem>
                    </Menu>
                </>

            }

        </>

    );
};

export default Modules;

