import React, { useContext, useEffect } from 'react'
import './Header.css';
import { Alert, Avatar, Box, Dialog, DialogActions, DialogContent, DialogContentText, Grid, ListItemIcon, Menu, MenuItem, Snackbar, Tooltip } from '@mui/material';
import img1 from '../../assets/Images/logo.png';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Termconditionpopup from '../Termconditionpopup/Termconditionpopup';
import Button from '@mui/material/Button';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useLocation, useNavigate } from 'react-router-dom';
import { Context as mainContext } from '../../Context/auth_context'
import { logoutApiCall, sendVerificationCode, updateCookieData, UserMeAPI } from '../../service/login.service'
import { useState } from 'react';
import { useLayoutEffect } from 'react';
import Link from '@mui/material/Link';
import TrainingPage from '../../Pages/TrainingPage/TrainingPage';
import LogoutIcon from '@mui/icons-material/Logout';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import LockResetIcon from '@mui/icons-material/LockReset';
// import RBPImage from '../../assets/Images/RBPImage.png'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountProfile from '../../Pages/AccountProfile/AccountProfile';
import { Logout, PersonAdd, Settings } from '@mui/icons-material';

/*
NOTE : If url changed for any page that is on header then please change below condition accordingly.
*/

const drawerWidth = 240;
const Header = (props) => {

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [companyData, setcompanyData] = React.useState({});
  const { UpdateUserDetails, UpdateLoader, UpdatelogoutUserDetails, state, updateCalculationHistory } = useContext(mainContext)

  const navigate = useNavigate();
  const [currentBtn, setCurrentBtn] = useState(false);
  const [allowCookie, setAllowCookie] = React.useState(false);
  // dropdown menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const container = window !== undefined ? () => window().document.body : undefined;
  const location = useLocation();
  var temp = location.pathname
  const [showMessage, setShowMessage] = useState({
    'status': false,
    'error': false,
    'message': ""

  })
  const [message, setMessage] = useState({
    status: false,
    message: "",
    error: "",
  });
  // const [openpasswordfiledstate, setopenpasswordfiledstate] = useState(false)

  const handleClose1 = () => {
    setMessage({
      status: false,
      message: "",
      error: "",
    })
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logout_btn = async () => {
    UpdateLoader(true)
    logoutApiCall({}, (res) => {
      UpdateLoader(false)
      UpdatelogoutUserDetails()
      navigate("/login")
    }, (err) => {
      UpdateLoader(false)
      UpdatelogoutUserDetails()
      navigate("/login")
    })
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (state.user_detail.loggedIn && location.pathname.indexOf("userprofile") == -1) {
      updateCalculationHistory(0)
    }
    if (state.user_detail.loggedIn && state.user_detail.user_details.user_status_id == 1) {
      // debugger
      navigate('/verify_user')
    }
    else {
      if (state.user_detail.loggedIn && !state.user_detail.user_details.cookie_flag) {
        // setAllowCookie(true)
      }
      if (location.pathname == "/verify_user") {
        if (state.user_detail.loggedIn && state.user_detail.user_details.user_status_id == 2) {
          navigate('/dashboard')
        }
      }
    }


  }, [])

  const clickAcceptCookie = () => {
    return
    var payload = {
      cookie_flag: true
    }
    updateCookieData(payload, (res) => {
      setAllowCookie(false)
      UserMeAPI((res) => {
        UpdateUserDetails(res)
      },
        (err) => {
          console.log(err)
        })
    }, (err) => {
      console.log(err)
    })
  }

  const sendtestverificationCodefun = () => {
    UpdateLoader(true)
    sendVerificationCode((res) => {

      if (res.data.status) {

        UpdateLoader(false)
        // setopenpasswordfiledstate(true)
        setShowMessage({
          'status': true,
          'error': false,
          'message': "Mail has been sent successfully!"
        })
      } else {
        UpdateLoader(false)
        setShowMessage({
          'status': false,
          'error': true,
          'message': "Something Went Wrong!"
        })
      }
    }, (err) => {
      UpdateLoader(false)
      setShowMessage({
        'status': false,
        'error': true,
        'message': "Something Went Wrong!"
      })
    })
  }



  const drawer = (


    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Divider />
      {state.user_detail.loggedIn &&
        <>
          <Grid container className='header-side-bar-profile'>
            <Grid item xs={3}>
              {state.user_detail.user_details.firstname ?
                (state.user_detail.user_details.user_profile_image) ?
                  <Avatar className='header-profile-image' sx={{ width: 30, height: 30 }}
                    src={state.user_detail.user_details.user_profile_image ? state.user_detail.user_details.user_profile_image : null}>
                  </Avatar>

                  : <Avatar className='header-profile-image' sx={{ width: 30, height: 30, fontSize: "0.8rem" }}>{state.user_detail.user_details ? (state.user_detail.user_details?.firstname.charAt(0).toUpperCase(0) + "" + state.user_detail.user_details?.lastname.charAt(0).toUpperCase(0)) : ""}</Avatar>
                :
                <Avatar className='header-profile-image' sx={{ width: 30, height: 30 }}></Avatar>
              }

            </Grid>
            <Grid item xs={9} className='header-side-bar-name'>
              <span className='header-side-bar-username'>{state.user_detail.user_details.firstname.charAt(0).toUpperCase() + "" + state.user_detail.user_details.firstname.slice(1, state.user_detail.user_details.firstname.length) + " " + state.user_detail.user_details.lastname.charAt(0).toUpperCase() + "" + state.user_detail.user_details.lastname.slice(1, state.user_detail.user_details.lastname.length)}</span>
            </Grid>
          </Grid>
          <Divider className='header-hr' />


          <List>
            <ListItem key={1} disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }} className={currentBtn === "Home" ? "header-btn-highlighter" : "header-btn-normal"}>
                <ListItemText primary={"Home"} sx={{ textTransform: "capitalize" }} onClick={(e) => { navigate("/dashboard") }} />
              </ListItemButton>
            </ListItem>

            {(state.user_detail.role_id == 1) &&
              <ListItem key={2} disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }} className={currentBtn === "Agent" ? "header-btn-highlighter" : "header-btn-normal"}>
                  <ListItemText primary={"Agents"} sx={{ textTransform: "capitalize" }} onClick={(e) => { navigate("/agent") }} />
                </ListItemButton>
              </ListItem>
            }

            <ListItem key={2} disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }} className={currentBtn === "Profile" ? "header-btn-highlighter" : "header-btn-normal"}>
                <ListItemText primary={"Profiles"} sx={{ textTransform: "capitalize" }} onClick={(e) => { navigate("/profile") }} />
              </ListItemButton>
            </ListItem>

            {/* <ListItem key={3} disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }} className={currentBtn === "Report" ? "header-btn-highlighter" : "header-btn-normal"}>
                <ListItemText primary={"Report"} sx={{ textTransform: "capitalize" }} onClick={(e) => { navigate("/report") }} />
              </ListItemButton>
            </ListItem> */}

            <ListItem key={1} disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }} className={currentBtn === "Training" ? "header-btn-highlighter" : "header-btn-normal"}>
                <ListItemText primary={"Training"} sx={{ textTransform: "capitalize" }} onClick={(e) => { navigate("/trainingpage") }} />
              </ListItemButton>
            </ListItem>

            <ListItem key={4} disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }} className={currentBtn === "AccountProfile" ? "header-btn-highlighter" : "header-btn-normal"}>
                <ListItemText primary={"My Account"} sx={{ textTransform: "capitalize" }} onClick={(e) => { navigate("/accountprofile") }} />
              </ListItemButton>
            </ListItem>

            <ListItem key={5} disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }} className={currentBtn === "ChangePassword" ? "header-btn-highlighter" : "header-btn-normal"}>
                <ListItemText primary={"Change Password"} sx={{ textTransform: "capitalize" }} onClick={(e) => { navigate("/changepassword") }} />
              </ListItemButton>
            </ListItem>

            <ListItem key={6} disablePadding>
              <ListItemButton onClick={logout_btn} sx={{ textAlign: 'center' }}>
                <ListItemText primary={"Logout"} sx={{ textTransform: "capitalize" }} />
              </ListItemButton>
            </ListItem>
          </List>
        </>
      }
      <Box className='sideSideBarFooter'>
        <img src="https://api.retirementbrain.io/static/images/powered_by_retirementbrain_logo.png" height={50} width={160} alt={state.company.company_description} />

        <Divider className='divider' />
        <Grid container direction="column" justifyContent="flex-start" textAlign="left" spacing={1.2} >
          <Grid item xs={12} className="copyright">Copyright © All Rights Reserved 2022.</Grid>
          <Grid item xs={12}><Link href="/termsandconditions" target="_blank" className="mobile-footer-text">Terms and Conditions</Link></Grid>
          <Grid item xs={12}><Link href="/privacypolicy" target="_blank" className="mobile-footer-text">Privacy Policy</Link></Grid>
          <Grid item xs={12}><Link href="/useragreement" target="_blank" className="mobile-footer-text">User Agreement</Link></Grid>
        </Grid>
      </Box>

    </Box>
  );

  useEffect(() => {

    /*
      NOTE : If url changed for any page that is on header then please change below condition accordingly.
    */
    if (location.pathname === "/dashboard") {
      setCurrentBtn("Home");
    }
    else if (location.pathname === "/agent") {
      setCurrentBtn("Agent");
    }
    else if (location.pathname === "/userprofile") {
      setCurrentBtn("UserProfile")
    }
    else if (location.pathname === "/profile") {
      setCurrentBtn("Profile")
    }
    else if (location.pathname === "/trainingpage") {
      setCurrentBtn("Training")
    }
    else if (location.pathname === "/profile/calculators") {
      setCurrentBtn("Profile")
    }
    else if (location.pathname === "/report") {
      setCurrentBtn("Report")
    }
    else if (location.pathname === "/accountprofile") {
      setCurrentBtn("AccountProfile")
    }
    else if (location.pathname === "/changepassword") {
      setCurrentBtn("ChangePassword")
    }



  }, [location])

  return (
    <>
      {message.status &&
        <Snackbar open={true} autoHideDuration={6000} onClose={handleClose1}>
          <Alert onClose={handleClose1} severity={message.error == true ? "error" : "success"} sx={{ width: '100%' }}>
            {message.message}
          </Alert>
        </Snackbar>
      }
      {allowCookie &&
        <React.Fragment key={"bottom"}>
          <Drawer anchor={"bottom"} open={true}>
            <Grid container spacing={5} padding={5} sx={{ backgroundColor: "#0c3b69", color: "#fff" }}>
              <Grid item lg={9} md={9} xs={12} sm={12}>
                This site uses cookies. Some of the cookies we use are essential for parts of the site to operate and have already been set.
                You may delete and block all cookies from this site, but parts of the site will not work. Detailed information about the use of cookies on this website is available by clicking on <Link href="/privacypolicy" underline="always">{'more information'}</Link>.
              </Grid>
              <Grid item lg={3} md={3} xs={12} sm={12} textAlign="center">
                <Button variant="contained" onClick={clickAcceptCookie} color="success">Yes, I Accept Cookies</Button>
              </Grid>
            </Grid>
          </Drawer>
        </React.Fragment>
      }
      <Box sx={{ display: 'flex' }} className="header-main-box" >
        <AppBar className='border' component="nav">
          <Toolbar className='headerLineargrediant' >
            {state.user_detail.loggedIn &&
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                className='drawerThreeLine'
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              ><MenuIcon />
              </IconButton>}

            <Typography
              variant="h6"
              component="div"
              sx={{ display: { xs: 'block', sm: 'block' }, width: state.user_detail.loggedIn ? "auto" : "100%" }}
            >
              {/* lOGO IMAGE */}
              <img className='header-logo-desktop-img' src={state.company.company_logo} height={40} alt={state.company.company_description} onClick={(e) => { state.user_detail.loggedIn ? navigate('/dashboard') : navigate('/login') }} />
            </Typography>


            {state.user_detail.loggedIn &&
              <Box className='mobileHeaderIcon'>
                {/* <Button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  sx={{ color: "black !important" }}
                >
                </Button> */}
                {/* <PersonOutlineIcon className="mobileLogoutIcon" /> <KeyboardArrowDownIcon className="mobileLogoutIcon" fontSize='small' /> */}
                {/* <LogoutIcon fontSize="small" /> */}
                {/* <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  className='header-dropdown-btn'
                >
                  <MenuItem onClick={logout_btn} >Logout</MenuItem>
                </Menu> */}
              </Box>
            }

            {state.user_detail.loggedIn &&
              <>
                <Box sx={{ display: { xs: 'none', sm: 'block', marginRight: "10px" } }} className="header-btn-field">
                  {(state.user_detail.user_details.user_status_id == 2) &&
                    (
                      <>
                        <Button key={"Home"} className={currentBtn === "Home" ? "header-btn-highlighter" : "header-btn-normal"} onClick={(e) => {
                          setCurrentBtn("Home")
                          navigate("/dashboard")
                        }}>Home</Button>

                        {(state.user_detail.role_id == 1) &&
                          <Button key={"Agent"} className={currentBtn === "Agent" ? "header-btn-highlighter" : "header-btn-normal"}
                            sx={{ marginRight: "10px" }}
                            onClick={() => {
                              setCurrentBtn("Agent")
                              navigate("/agent")
                            }}>Agents
                          </Button>}

                        <Button key={"Profile"} className={currentBtn === "Profile" ? "header-btn-highlighter" : "header-btn-normal"}
                          sx={{ marginRight: "10px" }}
                          onClick={(e) => {
                            navigate("/profile")
                            setCurrentBtn("Profile")
                            navigate("/profile")
                          }}>Profiles
                        </Button>

                        {/* {(state.user_detail.role_id == 1) &&
                          <Button key={"Report"} className={currentBtn === "Report" ? "header-btn-highlighter" : "header-btn-normal"}
                            sx={{ marginRight: "10px" }}
                            onClick={(e) => {
                              navigate("/report")
                              setCurrentBtn("report")
                              navigate("/report")
                            }}>Report
                            </Button>} */}

                        <Button key={"Training"} className={currentBtn === "Training" ? "header-btn-highlighter" : "header-btn-normal"}
                          onClick={(e) => {
                            setCurrentBtn("Training")
                            navigate("/trainingpage")
                          }}>Training</Button>
                      </>
                    )}

                  {/* <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{ color: "black !important" }}
                  >{state.user_detail.name}<KeyboardArrowDownIcon />
                  </Button> */}
                  {/* <Tooltip title="Account settings"> */}
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    {state.user_detail.user_details.firstname ?
                      (state.user_detail.user_details.user_profile_image) ?
                        <Avatar sx={{ width: 28, height: 28 }} src={state.user_detail.user_details.user_profile_image ? state.user_detail.user_details.user_profile_image : null}></Avatar> : <Avatar sx={{ width: 30, height: 30, fontSize: "0.8rem" }}>{state.user_detail.user_details ? (state.user_detail.user_details?.firstname.charAt(0).toUpperCase(0) + "" + state.user_detail.user_details?.lastname.charAt(0).toUpperCase(0)) : ""}</Avatar>
                      :
                      <Avatar sx={{ width: 28, height: 28 }}></Avatar>
                    }
                    <KeyboardArrowDownIcon />
                  </IconButton>
                  {/* </Tooltip> */}

                  <Menu
                    // id="basic-menu"
                    id="account-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0
                        }
                      }
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem className='header-dropdown-label' sx={{ justifyContent: "left" }} >&nbsp;&nbsp;
                      {state.user_detail.user_details.firstname.charAt(0).toUpperCase() + "" + state.user_detail.user_details.firstname.slice(1, state.user_detail.user_details.firstname.length) + " " + state.user_detail.user_details.lastname.charAt(0).toUpperCase() + "" + state.user_detail.user_details.lastname.slice(1, state.user_detail.user_details.lastname.length)}</MenuItem><Divider />
                    <MenuItem className='header-dropdown-label'
                      onClick={(e) => { navigate("/accountprofile"); }}>&nbsp;<AccountCircleIcon fontSize='medium' />&nbsp;My Account</MenuItem>

                    <MenuItem className='header-dropdown-label' onClick={(e) => { navigate('/changepassword'); sendtestverificationCodefun(); }}>&nbsp;<LockResetIcon fontSize='medium' />&nbsp;Change Password</MenuItem>
                    <MenuItem className='header-dropdown-label' onClick={logout_btn}>&nbsp;&nbsp;<Logout fontSize="medium" />&nbsp;Logout</MenuItem>
                  </Menu>
                </Box>
              </>
            }
            <Typography variant="h6" className='powerbyimgclasss' component="div" sx={{ display: { xs: 'block', sm: 'block', justifyContent: "end !important" }, }}>
              <img src="https://api.retirementbrain.io/static/images/powered_by_retirementbrain_logo.png" height={40} width={150} alt={state.company.company_description} />
            </Typography>
          </Toolbar>
        </AppBar>

        <Box component="nav">
          {state.user_detail.loggedIn && <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>}
        </Box>
      </Box>
      {/* <Divider /> */}
      {/* <hr /> */}

      {temp != '/login' && state.user_detail?.user_details?.user_agreement === false &&
        <Termconditionpopup />
      }
      {/* <ChangePasswordPopup ChangePasswordPopup={ChangePasswordPopup} /> */}
    </>
  );
}

export default Header;