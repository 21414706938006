import React from "react";
import Header from "../../GlobalComponent/Header/Header";
import './Register.css'
import { Grid, Typography, Box, TextField, Checkbox, FormControlLabel, FormGroup, Button, FormControl, InputLabel, Select, OutlinedInput, MenuItem, useTheme, Alert, Dialog, DialogContent } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import Link from '@mui/material/Link';
import LoginBG from "../../assets/Images/LoginBG.png"
import Loginblureimg from '../../assets/Images/Loginblureimg.png'
import CloseIcon from '@mui/icons-material/Close';
import Footer from "../../GlobalComponent/Footer/Footer";
import { CheckBox, CheckCircleRounded, SettingsInputSvideoRounded } from "@mui/icons-material";
import { useState } from "react";
import { getUserRoleDetail, registerNewUser } from "../../service/login.service";
import { useContext } from "react";
import { Context } from "../../Context/auth_context";
import { useLocation, useNavigate } from 'react-router-dom'


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Agent',
  'consumer'
];
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


const Register = () => {

  const theme = useTheme();
  const navigate = useNavigate();
  const [personName, setPersonName] = React.useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showMessage, setShowMessage] = useState({
    'status': false,
    'error': false,
    'message': "tesy"
  })
  const [flagRegisterPopup, setFlagRegisterPopup] = useState(false);
  const [flagUserRegistered, setFlagUserRegistered] = useState(false);
  const { UpdateUserDetails, UpdateLoader } = useContext(Context);


  const [userDetail, setUserDetail] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    agentOrConsumer: "",
    password: "",
    confirmPassword: "",
    agentCheckbox1: false,
    agentCheckbox2: false,
    consumerCheckbox1: false,
    captcha: ""
  });

  const handleChange = (e) => {
    if (e.target.value === "consumer") {
      setShowPassword(false);
      setUserDetail({
        ...userDetail,
        agentOrConsumer: e.target.value,
        password: "",
        confirmPassword: "",
        consumerCheckbox1: true,
        agentCheckbox2: false,
        agentCheckbox1: false
      })
    }
    else {
      setShowPassword(true)
      setUserDetail({
        ...userDetail,
        agentOrConsumer: e.target.value,
        agentCheckbox2: false,
        consumerCheckbox1: false
      })
    }
  };

  function onChange(value) {
    setUserDetail({
      ...userDetail,
      captcha: value
    })
  }

  const RegisterUser = () => {
    setShowMessage({
      status: true,
      error: false,
      message: ""
    })
    const emailRegex = /^([a-zA-Z0-9\._]+)@([a-zA-Z)-9])+.([a-z]+)(.[a-z]+)?$/
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/


    const {
      firstName,
      lastName,
      email,
      phone,
      agentOrConsumer,
      agentCheckbox1,
      agentCheckbox2,
      consumerCheckbox1,
      confirmPassword,
      password,
      captcha
    } = userDetail;

    if (!firstName || !lastName || !email || !phone || !agentOrConsumer || (agentOrConsumer === "Agent" ? !password || !confirmPassword || !agentCheckbox1 || !agentCheckbox2 : false) || !captcha) {
      setShowMessage({
        status: false,
        error: true,
        message: "Please fill all required fields"
      })
      return
    }
    else if (agentOrConsumer === "Agent" && !emailRegex.test(email)) {
      setShowMessage({
        status: false,
        error: true,
        message: "Invalid Email Address"
      })
      return
    }
    else if (agentOrConsumer === "Agent" && !passwordRegex.test(password)) {
      setShowMessage({
        status: false,
        error: true,
        message: "Password must be Minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character"
      })
      return
    }
    else if (agentOrConsumer === "Agent" && confirmPassword !== password) {
      setShowMessage({
        status: false,
        message: "Password does not match",
        error: true,
      })
      return
    }
    else {
      let payload;
      UpdateLoader(true);
      getUserRoleDetail({}, (res) => {
        if (res.data.status === true) {
          let agentRoleDetail = res.data.data.find(a => a.user_role_type === "Agent");
          let consumerRoleDetail = res.data.data.find(a => a.user_role_type === "Consumer");
          if (agentOrConsumer === "Agent") {
            payload = {
              first_name: firstName,
              last_name: lastName,
              email_address: email,
              contact_number: phone,
              password: password,
              confirm_password: confirmPassword,
              user_role_id: agentRoleDetail.user_role_id,
              agent_licensed_checkbox: agentCheckbox1,
              user_agreement_checkbox: agentCheckbox2,
              created_by_admin_flag: false,
              re_captcha: captcha
            }
          }
          else {
            payload = {
              first_name: firstName,
              last_name: lastName,
              email_address: email,
              contact_number: phone,
              password: "",
              confirm_password: "",
              user_role_id: consumerRoleDetail.user_role_id,
              agent_licensed_checkbox: false,
              user_agreement_checkbox: consumerCheckbox1,
              created_by_admin_flag: false,
              re_captcha: captcha
            }
          }
          registerNewUser(payload, (res) => {
            if (res.data.status) {
              setFlagRegisterPopup(true);
            }
            else {
              if (res.data.error.code == 9003) {
                setShowMessage({
                  status: false,
                  error: true,
                  message: res.data.error.message
                })
              }
              else {
                setShowMessage({
                  status: false,
                  error: true,
                  message: res.data.error.message
                })
              }
            }
            UpdateLoader(false);
          },
            (err) => {
              setShowMessage({
                status: false,
                error: true,
                message: "Something went wrong, please try again later!"
              })
              UpdateLoader(false);
            })
        }
      },
        (err) => {
          UpdateLoader(false);
          setShowMessage({
            status: false,
            error: true,
            message: "Something went wrong, please try again later!"
          })
        })
    }
  }

  return (
    <>
      {flagRegisterPopup && <Dialog
        open={true}
        onClose={() => { setFlagRegisterPopup(false); navigate('/login'); setFlagUserRegistered(false) }}
        aria-labelledby="responsive-dialog-title">
        <DialogContent>
          <Grid item xs={12}>
            <Grid container className="pr-assign-profile-popup">
              {/* <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                            <Grid container className="pr-assign-profile-popup-title-container">
                                
                              <Typography id="pr-assign-profile-popup-title">User Registered Successfully!</Typography>
                                
                            </Grid>
                            </Grid>

                            <Grid item>
                            <Grid container className="pr-assign-profile-popup-close-btn-container">
                                <Box id="pr-assign-profile-popup-close-btn"><CloseIcon onClick={() => { setFlagRegisterPopup(false); setFlagUserRegistered(false) }} /></Box>
                            </Grid>
                            </Grid>
                        </Grid>
                    </Grid> */}

              <Grid item xs={11} >
                <Grid container className="pr-assign-agent-dropdown-container">

                  <Grid item xs={12}>
                    <Grid container className="pr-assign-agent-dropdown-label" textAlign={"center"}>
                      <CheckCircleRounded sx={{ color: "green", width: "60px", height: "60px", margin: "0px auto 15px" }} />

                    </Grid>
                  </Grid>

                  <Grid item xs={12} textAlign="center">
                    <Grid container className="pr-assign-agent-dropdown-label" justifyContent="center">
                      <Typography id="pr-assign-agent-dropdown-label">User Registered Successfully!!<br /><span style={{ color: "#7a7a7a" }}>Verify your email address from link that has been sent to your email.</span></Typography>

                    </Grid>
                  </Grid>


                </Grid>
              </Grid>

              <Grid item xs={7} >
                <Grid container className="pr-save-cancel-popup-container-agent" justifyContent="center" marginTop='10px'>
                  <Grid item xs={5.6}>
                    <Grid container className="avl-email-popup-btn" sx={{ display: "flex", justifyContent: "center", margin: "0 auto", width: "100%" }}>
                      <Button variant="outlined" sx={{ margin: "0 auto", justifyContent: "center" }} id="avl-email-save-popup-btn" onClick={() => { setFlagRegisterPopup(false); navigate('/login'); setFlagUserRegistered(false) }}>Close</Button>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>}
      <Header />
      <Grid container className="reg-main-container">
        <Grid item xl={12} lg={12} xs={12}>
          <Grid container className="reg-img-main-container">
            <Grid item xl={4} lg={5} md={6.5} sm={8} xs={11}>
              <Grid container className="reg-filter-container">
                <Grid item xl={8} lg={8} md={8} sm={8} xs={10.5} >
                  <Grid container className="reg-sub-container">

                    <Grid item xs={12} className="reg-title-item">
                      <span className="reg-title-text">Register</span>
                    </Grid>

                    {/* first name */}
                    <Grid item xs={12}>
                      <Grid container className="reg-fname-container">
                        <Grid item xs={12}>
                          {showMessage.error && <Alert severity="error" >{showMessage.message}</Alert>}
                          <span className="reg-label-text">First Name <span className="reg-astrisk-sign">*</span></span>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            required
                            autoComplete="off"
                            className="reg-textfields"
                            type="text"
                            id="outlined-size-small"
                            size="small"
                            placeholder="Enter First Name"
                            value={userDetail.firstName}
                            onChange={(e) => {
                              setUserDetail({
                                ...userDetail,
                                firstName: e.target.value
                              })
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* last name */}
                    <Grid item xs={12}>
                      <Grid container className="reg-lname-container">
                        <Grid item xs={12}>
                          <span className="reg-label-text">Last Name <span className="reg-astrisk-sign">*</span></span>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            required
                            autoComplete="off"
                            className="reg-textfields"
                            type="text"
                            id="outlined-size-small"
                            size="small"
                            placeholder="Enter Last Name"
                            value={userDetail.lastName}
                            onChange={(e) => {
                              setUserDetail({
                                ...userDetail,
                                lastName: e.target.value
                              })
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* email */}
                    <Grid item xs={12}>
                      <Grid container className="reg-email-container">
                        <Grid item xs={12}>
                          <span className="reg-label-text">Email <span className="reg-astrisk-sign">*</span></span>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            required
                            className="reg-textfields"
                            type="email"
                            id="outlined-size-small"
                            size="small"
                            placeholder="Enter Email"
                            value={userDetail.email}
                            onChange={(e) => {
                              setUserDetail({
                                ...userDetail,
                                email: e.target.value
                              })
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* phone */}
                    <Grid item xs={12}>
                      <Grid container className="reg-phone-container">
                        <Grid item xs={12}>
                          <span className="reg-label-text">Phone <span className="reg-astrisk-sign">*</span></span>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            required
                            autoComplete="off"
                            className="reg-textfields"
                            type="text"
                            id="outlined-size-small"
                            size="small"
                            placeholder="Enter Phone"
                            value={userDetail.phone}
                            onChange={(e) => {
                              const contactRegx = /^[0-9\b]+$/
                              if (e.target.value === '' || contactRegx?.test(e.target.value)) {
                                setUserDetail({
                                  ...userDetail,
                                  phone: e.target.value
                                })
                              }
                              else {
                                return false
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* dropdwn */}
                    <Grid item xs={12}>
                      <Grid container className="reg-dropdown-container">
                        <Grid item xs={12}>
                          <span className="reg-label-text">Are you an agent or a consumer? <span className="reg-astrisk-sign">*</span></span>
                        </Grid>
                        <Grid item xs={12} className="reg-select-dropdown-item">
                          <FormControl >

                            <Select
                              className="reg-select-dropdown"
                              labelId="demo-multiple-name-label"
                              id="demo-multiple-name"
                              size="small"
                              value={userDetail.agentOrConsumer}
                              onChange={handleChange}
                              input={<OutlinedInput label="name" />}
                              MenuProps={MenuProps}
                              displayEmpty
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return <Typography sx={{ color: "gray", opacity: "0.6" }}>Select-Role</Typography>;
                                }
                                return selected;
                              }}
                            >

                              {names.map((name) => (
                                <MenuItem
                                  key={name}
                                  value={name}
                                  style={getStyles(name, userDetail.agentOrConsumer, theme)}
                                  onClick={() => {
                                    if (name == "Agent") {
                                      setShowPassword(true);
                                    }
                                    else {
                                      setShowPassword(false);
                                    }
                                  }}>
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>

                    {showPassword &&

                      <React.Fragment>
                        {/* password */}
                        <Grid item xs={12}>
                          <Grid container className="reg-password-container">
                            <Grid item xs={12}>
                              <span className="reg-label-text" >Password <span className="reg-astrisk-sign">*</span></span>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                required
                                className="reg-textfields"
                                type="password"
                                id="outlined-size-small"
                                size="small"
                                placeholder="Enter Password"
                                value={userDetail.password}
                                onChange={(e) => {
                                  setUserDetail({
                                    ...userDetail,
                                    password: e.target.value
                                  })
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* password */}
                        <Grid item xs={12}>
                          <Grid container className="reg-cpassword-container">
                            <Grid item xs={12}>
                              <span className="reg-label-text">Confirm Password <span className="reg-astrisk-sign">*</span></span>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                required
                                className="reg-textfields"
                                type="password"
                                id="outlined-size-small"
                                size="small"
                                placeholder="Enter Password"
                                value={userDetail.confirmPassword}
                                onChange={(e) => {
                                  setUserDetail({
                                    ...userDetail,
                                    confirmPassword: e.target.value
                                  })
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    }

                    {/* password */}
                    <Grid item xs={12}>
                      <Grid container className="reg-terms-container">
                        {showPassword == true &&
                          <Grid item xs={12} className="reg-checkbox1">
                            <Checkbox checked={userDetail.agentCheckbox1} onChange={(e) => {
                              setUserDetail({
                                ...userDetail,
                                agentCheckbox1: e.target.checked
                              })
                            }} id="reg-checkbox" /><span className="reg-terms-label-text">Agent is a licensed financial professional</span>
                          </Grid>
                        }
                        <Grid item sm={12} xs={12} className="reg-checkbox2">
                          <Checkbox value={showPassword === true ? userDetail.agentCheckbox2 : userDetail.consumerCheckbox1} onChange={(e) => {
                            if (showPassword === true) {
                              setUserDetail({
                                ...userDetail,
                                agentCheckbox2: e.target.checked
                              })
                            }
                            else {
                              setUserDetail({
                                ...userDetail,
                                consumerCheckbox1: e.target.checked
                              })
                            }
                          }} id="reg-checkbox" /><span className="reg-terms-label-text">Agree to the User Agreement mentioned <Link underline="none" href="/useragreement" target="_blank" id="reg-term-text">here</Link></span>
                        </Grid>

                      </Grid>
                    </Grid>

                    {/* captcha */}
                    <Grid item xs={12} className="reg-gcaptcha-item">
                      {/* <Grid container>
                                          <Grid item xs={12}> */}
                      <Box className="captcha-box">
                        <ReCAPTCHA
                          sitekey="6Ld3zhcaAAAAALdQpxG7ktmwT_39Oahb1BPBjYDP"
                          onChange={(token) => {
                            setUserDetail({
                              ...userDetail,
                              captcha: token
                            })
                          }}
                        />
                      </Box>
                      {/* </Grid>
                                        </Grid> */}
                    </Grid>

                    {/* reg-btn */}
                    <Grid item xs={12} className="reg-button-item">
                      <Button variant="contained" id="reg-button" fullWidth onClick={RegisterUser} >Register</Button>
                    </Grid>

                    {/* reg-btn */}
                    <Grid item xs={12} className="reg-login-item">
                      <span className="reg-alredy-acc-text">Already have an account? <Link onClick={() => { navigate("/login"); }} underline="none" sx={{ cursor: "pointer", color: "white", fontWeight: "600" }} >Login</Link> </span>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default Register;