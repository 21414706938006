import { Button, Dialog, DialogContent, Grid } from '@mui/material'
import React, { useContext, useState } from 'react'
// import '../'
import CloseIcon from '@mui/icons-material/Close';
import { deleteUserProfileUrlAPI, extendUrlAPI, generateClientFacingURLAPI, getClientFacingUrlDataAPI, sendEmailUrlAPI } from '../../service/login.service';
import { Context as mainContext } from '../../Context/auth_context'
import { useLocation, useSearchParams } from "react-router-dom";


const ShareDeletePopup = (props) => {


    const { UpdateLoader, state } = useContext(mainContext)
    const [message, setMessage] = useState({
        status: false,
        message: "",
        error: "",
    });

    const generateUrlCall = () => {

        // if (!props.changeEmail) {
        //     return;
        // }
        let obj = {
            "user_profile_id": props.data.inputData.profile_id,
            "email": props.changeEmail,
            "send_email_to_client_flag": props.saveFlag
        }
        generateClientFacingURLAPI(obj, (res) => {
            if (res.data.status == true) {
                props.setActivityLogData(res.data.data[0].client_facing_url_activity_log)
                props.setUrlDetailsData(res.data.data[0])
                let userDetails = res.data.data[0]
                if (userDetails && !userDetails.client_facing_url_token_deleted) {
                    props.setSaveFlag(false);
                }
                UpdateLoader(false)
                // setUpdateSomething(!updateSomething)
                setMessage({
                    status: true,
                    message: "Data Saved Successfully",
                    error: false,
                })
                UpdateLoader(false)
            }
            else {
                setMessage({
                    status: true,
                    message: res.data.error.message,
                    error: true,
                })
            }
        },
            (err) => {
                console.log(err)
                UpdateLoader(false)
                setMessage({
                    status: true,
                    message: "Something Went Wrong!",
                    error: true,
                })
            })
    }

    return (
        <Dialog
            open={true}
            onClose={props.handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth="xs"
        >
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container className='clienturl-main-container'>


                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid item xs={12} className="cm-head-item">
                                            <span className="cm-heading-span"></span>
                                        </Grid>

                                    </Grid>

                                    <Grid item>
                                        <Grid container className="avl-email-link-popup-close-btn-container">
                                            <span id="avl-email-popup-close-btn"><CloseIcon onClick={() => { props.setDeleteLinkPopup(false) }} /></span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item xs={12} className="cm-label-text" >
                                <span className='cm-label-label'>
                                    Are you sure you want to extend link?
                                </span>
                            </Grid>

                            <Grid item xs={8} className="cm-continue-cancle-main-item">
                                <Grid container className="cm-continue-cancle-main-container">
                                    <Grid item xs={5.5} className="cm-close-item">
                                        <Button
                                            variant='outlined'
                                            className='cm-close-btn'
                                            fullWidth
                                            onClick={() => { props.setDeleteLinkPopup(false) }}
                                        >Close</Button>
                                    </Grid>
                                    <Grid item xs={5.5} className="cm-continue-item">
                                        <Button
                                            variant='contained'
                                            className='cm-continue-btn'
                                            fullWidth
                                            onClick={() => {
                                                generateUrlCall()

                                            }}
                                        >Continue</Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ShareDeletePopup;







