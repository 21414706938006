import { Button, Dialog, DialogContent, Grid } from '@mui/material'
import React, { useContext, useState } from 'react'
// import '../ConfirmMessagePopup/ConfirmMessagePopup.css'
import CloseIcon from '@mui/icons-material/Close';
import { deleteUserProfileUrlAPI, generateClientFacingURLAPI, getClientFacingUrlDataAPI, sendEmailUrlAPI } from '../../service/login.service';
import { Context as mainContext } from '../../Context/auth_context'
import { useLocation, useSearchParams } from "react-router-dom";


const RegenerateUrlPopup = (props) => {
    
    const { UpdateLoader, state } = useContext(mainContext)
    const [searchParams, setSearchParams] = useSearchParams();

    let id = searchParams.get("id");

    const regenerateUrl = (profileId) => {
        
        props.setProfileId(id)
        
        
        if (!props.changeEmail) {
            return;
        }


        
        let obj = {
            "user_profile_id": id,
            "email": props.changeEmail,
            "send_email_to_client_flag": props.saveFlag
        }

        generateClientFacingURLAPI(obj, (res) => {
            console.log("response:->", res);
            if (res.data.status == true) {
                props.setActivityLogData(res.data.data[0].client_facing_url_activity_log)
                props.setUrlDetailsData(res.data.data[0])
                let userDetails = res.data.data[0]
                if (userDetails && !userDetails.client_facing_url_token_deleted) {
                    props.setSaveFlag(false);
                }
                UpdateLoader(false)
                // setUpdateSomething(!updateSomething)
                getClientFacingUrlDataAPI(profileId,
                    (res) => {
                        props.setActivityLogData(res.data.data[0].client_facing_url_activity_log)
                        props.setUrlDetailsData(res.data.data[0])
                        let userDetails = res.data.data[0]
                        if (userDetails && !userDetails.client_facing_url_token_deleted) {
                            props.setSaveFlag(false);
                        }
                        UpdateLoader(false)
                        props.setMessage({
                            status: true,
                            message: "URL Regenerate Successfully",
                            error: false,
                        })
                    }, 
                    (err)=>{
                        props.setMessage({
                            status: true,
                            message: "Something Went Wrong!",
                            error: false,
                        })
                    })
                UpdateLoader(false)
            }
            else {
                props.setMessage({
                    status: true,
                    message: res.data.error.message,
                    error: true,
                })
            }
        },
            (err) => {
                console.log(err)
                UpdateLoader(false)
                props.setMessage({
                    status: true,
                    message: "Something Went Wrong! 1",
                    error: true,
                })
            })
    }

    // return (
    //     <Dialog
    //         open={true}
    //         onClose={props.handleClose}
    //         aria-labelledby="responsive-dialog-title"
    //         maxWidth="xs"
    //     >
    //         <DialogContent>
    //             <Grid container>
    //                 <Grid item xs={12}>
    //                     <Grid container className='clienturl-main-container'>
    //                         <Grid item xs={12}>
    //                             <Grid container>
    //                                 <Grid item xs={12}>
    //                                     <Grid item xs={12} className="cm-head-item">
    //                                         <span className="cm-heading-span">Delete Message Confirmation</span>
    //                                     </Grid>
    //                                 </Grid>

    //                                 <Grid item>
    //                                     <Grid container className="avl-email-link-popup-close-btn-container">
    //                                         <span id="avl-email-popup-close-btn"><CloseIcon onClick={props.handleClose} /></span>
    //                                     </Grid>
    //                                 </Grid>
    //                             </Grid>
    //                         </Grid>

    //                         <Grid item xs={12} className="cm-label-text" >
    //                             <span className='cm-label-label'>
    //                                 Are you sure you want to Regenerate URL?
    //                             </span>
    //                         </Grid>

    //                         <Grid item xs={8} className="cm-continue-cancle-main-item">
    //                             <Grid container className="cm-continue-cancle-main-container">
    //                                 <Grid item xs={5.5} className="cm-close-item">
    //                                     <Button
    //                                         variant='outlined'
    //                                         className='cm-close-btn'
    //                                         fullWidth
    //                                         onClick={props.handleClose}
    //                                     >Close</Button>
    //                                 </Grid>
    //                                 <Grid item xs={5.5} className="cm-continue-item">
    //                                     <Button
    //                                         variant='contained'
    //                                         className='cm-continue-btn'
    //                                         fullWidth
    //                                         onClick={() => {regenerateUrl(profileId)}}
    //                                     >Continue</Button>
    //                                 </Grid>
    //                             </Grid>
    //                         </Grid>

    //                     </Grid>
    //                 </Grid>
    //             </Grid>
    //         </DialogContent>
    //     </Dialog>
    // )



  
}

export default RegenerateUrlPopup;







