import { Grid, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { Context as mainContext } from '../../Context/auth_context'
import './PrivacyPolicy.css'

export const PrivacyPolicy = () => {

    const { state } = useContext(mainContext)

    return (
        <>


            <Grid container justifyContent="center" spacing={2}>
                <Grid item sm={8} xs={8} className="logoHeader">
                    <Grid container justifyContent="center" alignItems="center" spacing={1}>
                        <Grid item sm={12} xs={12} className="tncLogo">
                            <img src={state.company.company_logo} height={50} alt={state.company.company_description} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={12} xs={12} textAlign="center">
                    <h4 id="tncTitle">Privacy Policy</h4>
                </Grid>
                <Grid item sm={8} xs={8} className="tncContainer">
                    <Grid container spacing={3} className="paraData">
                        <Grid item sm={12} xs={12}>
                            <Typography variant='p'>Notice of RetirementBrain Privacy Policy and Website Privacy Statement</Typography>
                        </Grid>

                        <Grid item sm={12} xs={12}>
                            <h4 className='tncTitle'>Purpose of This Notice</h4>
                            <Typography variant='p'>This Notice provides information about the privacy policies and practices of RetirementBrain Because your privacy is very important to us, we have adopted the following privacy policy regarding how the information you share with us is used and maintained. We do not sell your information to third parties. We also maintain policies and procedures that are intended to ensure the responsible handling of your “nonpublic personal information.” The examples below of information we collect and the categories of parties with whom we share that information are intended to illustrate our information practices; they are not intended to be exhaustive. You do not need to take any action as a result of this notice, but you do have certain rights as described below.</Typography>
                        </Grid>

                        <Grid item sm={12} xs={12}>
                            <h4 className='tncTitle'>Our Privacy Policies & Practices for Consumers & Customers</h4>
                            <h5 className='tncSubTitle'>Information We Collect From Consumers and Customers</h5>
                            <Typography variant='p'>RetirementBrain may collect nonpublic personal information about consumers and customers from the following sources:</Typography>
                        </Grid>

                        <Grid item sm={12} xs={12}>
                            <ul className='unOrder'>
                                <li>Information we receive from affiliates of RetirementBrain</li>
                                <li>Information we receive from an individual and his or her employer from reports summarizing his or her insurance applications, plan participation forms, and other related documents.</li>
                                <li>Information based on an individual’s history of transactions executed with the assistance of RetirementBrain, our affiliates, or others. </li>
                                <li>Information We May Disclose About Consumers and Customers<br />
                                As permitted by law, we may disclose some or all of the information described above among RetirementBrain affiliates, and with companies or individuals that perform services necessary to effectuate transitions that an individual or his or her employer has requested. With respect to current and former customers, we may also disclose some or all of the information described above to nonaffiliated third parties, which may include:
                                </li>
                                <li>Companies or individuals that perform services for us or on our behalfs, such as independent contractors or technical system consultants who program our software.</li>
                                <li>Companies that perform marketing services on our behalf or other financial institutions with which we have joint marketing agreements.</li>
                                <li>Other nonaffiliated third parties, as permitted by law.</li>
                            </ul>
                        </Grid>

                        <Grid item sm={12} xs={12}>
                            <h4 className='tncTitle'>Our Practices Regarding the Confidentiality and Security of Consumer and Customer Information </h4>
                            <Typography variant='p'>We restrict access to nonpublic personal information about our current or former customers and consumers to those authorized individuals who need to know that information in order to provide them with products or services. We also require outside companies and independent contractors who provide services on our behalf or with whom we have entered a joint marketing agreement to enter into agreements that protect the confidentiality of any customer information we provide and restrict the use of such information for those purposes. Finally, we maintain physical, electronic, and procedural safeguards that comply with federal regulations to guard customers’ and consumers’ nonpublic personal information.</Typography>
                        </Grid>

                        <Grid item sm={12} xs={12}>
                            <h4 className='tncTitle'>Contacting RetirementBrain Regarding Web site Policies and Practices</h4>
                            <Typography variant='p'>If you have any questions about this website's privacy statement, the practices of this site, or your dealings with this Web site, you can contact:</Typography>
                        </Grid>
 
                        <Grid item xs={12} sml={12}>
                            <h4 className='tncTitle'>Do We use Cookies or Social Network Modules</h4>
                            <Typography variant='p' >Cookie files are files or fragments of information that may be saved on your computer or other Internet-compatible devices (for example, smartphones and tablets) when you visit our websites. This information frequently consists of alphanumeric strings that uniquely identify your computer, but they may also contain other information.</Typography>
                        </Grid>

                    
                        <Grid item sm={12} xs={12}  >
                            <h4 className='tncTitle'>We use different types of "cookies" (small text files that are placed on your device):</h4>
                            <ul className='unOrder' >
                                <li>user input cookies;</li>
                                <li>authentication cookies;</li>
                                <li>cookies storing your preferences and settings; </li>
                                <li>performance cookies;<br /></li>
                                <li>security (antifraud) cookies;</li>
                                <li>first party analytics cookies;</li>
                                <li>social network tracking cookies.</li>
                            </ul>
                            <Typography fontSize={15} marginTop="10px !important" marginBottom="15px !important">RetirementBrain 1-800-290-7226</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item sm={8} xs={8} className="tncDis">
                    <Grid container className='paraData'>
                        <Grid item sm={12} xs={12}>
                            <h4 className='tncTitle'>Disclaimer</h4>
                            <Typography variant='p'>
                                 This presentation is a general discussion of the topic presented, based on our current understanding of applicable tax laws, regulations, and rulings. This information and presentation should not be interpreted as investment or tax advice. In actual practice, the transactions discussed may be more complex and will require the attention and expertise of professional tax and legal advisors. In order to comply with certain U.S. Treasury regulations, please be advised of the following: unless expressly stated otherwise, any reference to U.S. Federal tax information contained in these materials, including any attachments, is not intended or written to be used, and cannot be used, by any person for the purpose of avoiding any penalties that may be imposed by the Internal Revenue Service. Values and benefits are not guaranteed unless stated as guaranteed on illustrations. Examples illustrate the potential benefits of indexed universal life insurance / IRS SEC. 7702 & 72(e). Portions of this presentation are based on NAIC-compliant life insurance illustrations which must be presented during the presentation and fully reviewed for additional disclosures.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>


        </>
    )
}
