import React, { useContext } from 'react';
import { Dialog, DialogContent, Grid, TextField, Typography, Button, Box } from "@mui/material"
import './DeleteUserProfilePopup.css';
import { deleteUserProfile, getUserProfileDetails } from '../../../src/service/login.service'
import CloseIcon from '@mui/icons-material/Close';
import { Context as mainContext } from '../../Context/auth_context'
import { ErrorRounded, CheckCircleRounded } from '@mui/icons-material';

const DeleteUserProfilePopup = (props) => {
    const { state, UpdateLoader } = useContext(mainContext)
    const [apistatus, setApistatus] = React.useState();
    const [agentProfileData, setAgentProfileData] = React.useState([{}]);

    const deleteUserProfilebtn = () => {
        UpdateLoader(true)
        if (!props.data.user_profile_id) return alert('Soemthing went wrong!')
        deleteUserProfile(props.data.user_profile_id, (res) => {
            props.showMessage({
                status: true,
                message: "Profile Deleted Successfully!",
                error: false,
            })
            props.handleClose()
            UpdateLoader(false)

        },
            (err) => {
                console.log("err")
                console.log(err)
                props.showMessage({
                    status: true,
                    message: "Something Went Wrong!",
                    error: true,
                })
                props.handleClose()
                UpdateLoader(false)
            })
    }
    return (

        <Dialog
            open={true}
            onClose={props.handleClose}
            aria-labelledby="responsive-dialog-title">
            <DialogContent>
                <Grid item xs={12}>
                    <Grid container className="pr-assign-profile-popup">
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container className="pr-assign-profile-popup-title-container">
                                        {apistatus ?
                                            (
                                                <>
                                                    <Typography id="pr-assign-profile-popup-title">Successfully Deleted!</Typography>
                                                </>
                                            ) : (
                                                <>
                                                    <Typography id="pr-assign-profile-popup-title">Delete {props.data.user_profile_name} Profile</Typography>
                                                </>
                                            )
                                        }

                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container className="pr-assign-profile-popup-close-btn-container">
                                        <Box id="pr-assign-profile-popup-close-btn"><CloseIcon onClick={props.handleClose} /></Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={7} lg={7} md={7} sm={7} xs={12} >
                            <Grid container className="pr-assign-agent-dropdown-container">

                                <Grid item xs={12}>
                                    <Grid container className="pr-assign-agent-dropdown-label" textAlign={"center"}>
                                        {apistatus ?
                                            (
                                                <>
                                                    <CheckCircleRounded fontSize="large" sx={{ color: "green" }} />
                                                </>
                                            ) : (
                                                <>
                                                    <ErrorRounded fontSize="large" sx={{ color: "red", width: "60px", height: "60px" }} />
                                                </>
                                            )
                                        }

                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container className="pr-assign-agent-dropdown-label">
                                        {apistatus ?
                                            (
                                                <>
                                                    <Typography id="pr-assign-agent-dropdown-label">Profile Deleted Successfully!!</Typography>
                                                </>
                                            ) : (
                                                <>
                                                    <Typography id="pr-assign-agent-dropdown-label">Are you sure you want delete this profile?</Typography>
                                                </>
                                            )
                                        }

                                    </Grid>
                                </Grid>


                            </Grid>
                        </Grid>

                        <Grid item xl={7} lg={7} md={7} sm={7} xs={12} >
                            <Grid container className="pr-save-cancel-popup-container">
                                {apistatus ?
                                    (
                                        <>
                                            <Grid item xs={5.6}>
                                                <Grid container className="avl-email-popup-btn">
                                                    <Button variant="outlined" id="avl-email-save-popup-btn" onClick={props.handleClose}>Close</Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            <Grid item xs={5.6}>
                                                <Grid container className="avl-email-popup-btn">
                                                    <Button variant="outlined" id="avl-email-save-popup-btn" onClick={props.handleClose}>No</Button>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={5.6}>
                                                <Grid container className="pr-save-popup-btn">
                                                    <Button variant="contained" id="pr-save-popup-btn" onClick={deleteUserProfilebtn}>Yes</Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )
                                }

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default DeleteUserProfilePopup;