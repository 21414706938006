import React, { useContext } from 'react';
import './UPSPComparison.css';
import { Avatar, Button, Divider, Grid, TextField, Typography, MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";
import Header from "../../GlobalComponent/Header/Header";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { Line } from "react-chartjs-2";
// import Chart from 'chart.js/auto';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from 'react';
import { getGraphDetails, getGraphDetailsPublic } from "../../service/login.service"
import { Context as mainContect } from '../../Context/auth_context';
import ShareIcon from '@mui/icons-material/Share';
import CreateIcon from '@mui/icons-material/Create';
import OutputAction from "../OutputAction/OutputAction";
import { Chart } from "react-google-charts";
import CanvasJSReact from '../../assets/canvasjs/canvasjs.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const UPSPComparison = (props) => {

    const navigate = useNavigate();
    const { UpdateLoader } = useContext(mainContect)
    const [startPeriod, setStartPeriod] = React.useState('1930');
    const [endPeriod, setEndPeriod] = React.useState('2022');
    const [nowShowGrah, setNowShowGrah] = React.useState(false);
    const [investorReturn, setInvestorReturn] = React.useState(3);
    const [spCap, setSPCap] = React.useState(14);
    const [startAmount, setStartAmount] = React.useState(14);
    const [period, setPeriod] = React.useState([1930, 1931, 1932, 1933, 1934, 1935, 1936, 1937, 1938, 1939, 1940, 1941, 1942, 1943, 1944, 1945, 1946, 1947, 1948, 1949, 1950, 1951, 1952, 1953, 1954, 1955, 1956, 1957, 1958, 1959, 1960, 1961, 1962, 1963, 1964, 1965, 1966, 1967, 1968, 1969, 1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977, 1978, 1979, 1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022])
    const [irList, setIRList] = React.useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
    const [spCapList, setSpCapList] = React.useState([0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20])
    const [fullResdata, setFullResdata] = React.useState({});

    // const [graphData, setGraphData] = React.useState({
    //     labels: [],
    //     datasets: [
    //       {
    //         label: "S & P",
    //         data: [],
    //         fill: false,
    //         borderColor: "#3B78CE"
    //       },
    //       {
    //         label: "S & P 0% Floor14% Cap",
    //         data: [],
    //         fill: false,
    //         borderColor: "#00FF00"
    //       },
    //       {
    //         label: "Investor Return",
    //         data: [],
    //         fill: false,
    //         borderColor: "#FFA500"
    //       }
    //     ]
    // });

    const [graphDataNew, setGraphDataNew] = React.useState({
        animationEnabled: true,
        // title:{
        //     text: "Monthly Sales - 2017"
        // },
        axisX: {
            valueFormatString: "#0,."
        },
        axisY: {
            // title: "Sales (in USD)",
            prefix: "$"
        },
        legend: {
            horizontalAlign: "right", // "center" , "right"
            verticalAlign: "top",  // "top" , "bottom"
            fontSize: 15
        },
        // toolTip: {
        //     shared: true
        // },

        data: [{
            type: "spline",
            name: "S&P",
            showInLegend: true,
            toolTipContent: "<span style=\"color:#4F81BC\"><strong>{name}: </strong></span> ${y}<br><span style=\"color:#4F81BC\"><strong>Year: </strong></span> {label}",
            dataPoints: []
        }, {
            type: "spline",
            name: "S & P 0% Floor 14% Cap",
            showInLegend: true,
            toolTipContent: "<span style=\"color:#c0504e\"><strong>{name}: </strong></span> ${y}<br><span style=\"color:#c0504e\"><strong>Year: </strong></span> {label}",
            dataPoints: []
        }, {
            type: "spline",
            name: "Investor Return",
            showInLegend: true,
            toolTipContent: "<span style=\"color:#9bbb58\"><strong>{name}: </strong></span> ${y}<br><span style=\"color:#9bbb58\"><strong>Year: </strong></span> {label}",
            dataPoints: []
        }]
    });


    const [searchParams, setSearchParams] = useSearchParams();
    const formatNumber = (q) => {
        if (!q) return q;
        return q.toLocaleString('en-US', {
            style: 'currency',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            currency: "usd"
        })
    }
    const [publicUrl, setPublicUrl] = React.useState(false)
    const [publicToken, setPublicToken] = React.useState()
    const [publicProfileId, setPublicProfileId] = React.useState()

    useEffect(() => {
        let token = searchParams.get("token");
        let profile_id = searchParams.get("profile_id");
        if (token && profile_id) {

            setPublicUrl(true)
            setPublicToken(token)
            setPublicProfileId(profile_id)
        }
    }, [])
    useEffect(() => {
        UpdateLoader(true)
        let obj = {
            "start_year": startPeriod,
            "end_year": endPeriod,
            "investor_return": investorReturn,
            "sp_cap": spCap,
            "start_amount": "100000"
        }
        let token = searchParams.get("token");

        if (publicUrl || token) {
            obj['token'] = token;
            getGraphDetailsPublic(obj,
                (res) => {
                    let data = res.data
                    if (data.status) {
                        let finalData = JSON.parse(data.data)
                        setFullResdata(finalData)

                        let graph_data = JSON.parse(JSON.stringify(graphDataNew))
                        // let graph_data = JSON.parse(JSON.stringify(graphData))
                        // graph_data['labels']=[]
                        // graph_data['datasets'][0]['data']=[]
                        // graph_data['datasets'][1]['data']=[]
                        // graph_data['datasets'][1]['label'] = (finalData?.mainspfcname)
                        // graph_data['datasets'][2]['data']=[]
                        // finalData.data.map(rr=>{
                        //     graph_data['labels'].push(rr.year)
                        //     graph_data['datasets'][0]['data'].push(rr.sp)
                        //     graph_data['datasets'][1]['data'].push(rr.spfc)
                        //     graph_data['datasets'][2]['data'].push(rr.ir)
                        // })

                        graph_data['data'][1]['name'] = (finalData?.mainspfcname)
                        graph_data['data'][0]['dataPoints'] = []
                        graph_data['data'][1]['dataPoints'] = []
                        graph_data['data'][2]['dataPoints'] = []
                        finalData.data.map(rr => {
                            graph_data['data'][0]['dataPoints'].push({ label: rr.year.toString(), y: rr.sp })
                            graph_data['data'][1]['dataPoints'].push({ label: rr.year.toString(), y: rr.spfc })
                            graph_data['data'][2]['dataPoints'].push({ label: rr.year.toString(), y: rr.ir })
                            // graph_data['datasets'][0]['data'].push(rr.sp)
                            // graph_data['datasets'][1]['data'].push(rr.spfc)
                            // graph_data['datasets'][2]['data'].push(rr.ir)
                        })
                        console.log(graph_data);
                        let gg = graph_data
                        // setGraphData(graph_data)
                        setGraphDataNew(graph_data)
                        setTimeout(function () {
                            setNowShowGrah(true)
                        }, 100)
                    }
                    UpdateLoader(false)
                }, (err) => {
                    console.log(err)
                    UpdateLoader(false)
                }
            )
        } else {
            getGraphDetails(obj,
                (res) => {
                    let data = res.data
                    if (data.status) {
                        let finalData = JSON.parse(data.data)
                        setFullResdata(finalData)

                        // let graph_data = JSON.parse(JSON.stringify(graphData))
                        // graph_data['labels']=[]
                        // graph_data['datasets'][0]['data']=[]
                        // graph_data['datasets'][1]['data']=[]
                        // graph_data['datasets'][1]['label'] = (finalData?.mainspfcname)
                        // graph_data['datasets'][2]['data']=[]
                        // finalData.data.map(rr=>{
                        //     graph_data['labels'].push(rr.year)
                        //     graph_data['datasets'][0]['data'].push(rr.sp)
                        //     graph_data['datasets'][1]['data'].push(rr.spfc)
                        //     graph_data['datasets'][2]['data'].push(rr.ir)
                        // })
                        // setGraphData(graph_data)

                        let graph_data = JSON.parse(JSON.stringify(graphDataNew))
                        graph_data['data'][1]['name'] = (finalData?.mainspfcname)
                        // graph_data['data'][0]['dataPoints'] = []
                        graph_data['data'][0]['dataPoints'] = []
                        graph_data['data'][1]['dataPoints'] = []
                        graph_data['data'][2]['dataPoints'] = []
                        finalData.data.map(rr => {
                            graph_data['data'][0]['dataPoints'].push({ label: rr.year.toString(), y: rr.sp })
                            graph_data['data'][1]['dataPoints'].push({ label: rr.year.toString(), y: rr.spfc })
                            graph_data['data'][2]['dataPoints'].push({ label: rr.year.toString(), y: rr.ir })
                        })
                        setGraphDataNew(graph_data)
                        setTimeout(function () {
                            setNowShowGrah(true)
                        }, 100)

                    }
                    UpdateLoader(false)
                }, (err) => {
                    console.log(err)
                    UpdateLoader(false)
                }
            )
        }
    }, [startPeriod, endPeriod, investorReturn, spCap])

    return (
        <>

            {window.screen.width > 900 &&

                <Grid container className="up-ri-white-container2"  >
                    {/* Fees */}
                    <Grid item xl={1} lg={1} md={1} xs={1}>
                        <Grid container className="upsp-blue-vertical-container" >
                            <Grid item xl={12} lg={12} md={12} xs={12} className="upsp-blue-vertical-item">
                                <span className="upsp-blue-vertical-text">S&P Comparison</span>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* table-graph container */}
                    <Grid item xl={10.5} lg={10.5} md={10.5} xs={10.5} >
                        <Grid container className='sp-main-container'>

                            {/* item-1 */}
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Grid container className='sp-time-variable-container'>
                                    {/* TIME PERIOD */}
                                    <Grid item xl={3} lg={4} md={5} sm={12} xs={3} >
                                        <Grid container>
                                            {/* time label */}
                                            <Grid item xl={12} lg={12} xs={12} >
                                                <Grid container className="sp-time-period-container">
                                                    <Typography id="sp-time-period-label">Time Period</Typography>
                                                </Grid>
                                            </Grid>

                                            {/* year label */}
                                            <Grid item xl={12} lg={12} xs={12}>
                                                <Grid container className='sp-time-start-end-container'>
                                                    <Grid item xl={5.95} lg={5.95} xs={5.95} >
                                                        <Grid container className="sp-time-start-container">
                                                            <Typography id="sp-time-start-end-label">Start Period</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xl={5.95} lg={5.95} xs={5.95}>
                                                        <Grid container className="sp-time-end-container">
                                                            <Typography id="sp-time-start-end-label">End Period</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {/* year */}
                                            <Grid item xl={12} xs={12}>
                                                <Grid container className='sp-time-year-container'>
                                                    <Grid item xl={5.95} lg={5.95} xs={5.95} >
                                                        <Grid container className="sp-time-start-year-container">
                                                            <Grid item xl={12} xs={12}>
                                                                <FormControl sx={{ m: 0, width: "100% " }} size="small" >
                                                                    <Select
                                                                        labelId="demo-select-small"
                                                                        id="demo-select-small"
                                                                        value={startPeriod}
                                                                        onChange={(e) => { setStartPeriod(e.target.value) }}>
                                                                        {period.map((rr) => {
                                                                            return (
                                                                                <MenuItem value={rr}>{rr}</MenuItem>
                                                                            )
                                                                        })
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xl={5.95} lg={5.95} xs={5.95}>
                                                        <Grid container className="sp-time-end-year-container">
                                                            <Grid item xl={12} lg={12} xs={12}>
                                                                <FormControl sx={{ m: 0, width: "100%" }} size="small" >
                                                                    <Select
                                                                        labelId="demo-select-small"
                                                                        id="demo-select-small"
                                                                        name="endPeriod"
                                                                        value={endPeriod}
                                                                        onChange={(e) => { setEndPeriod(e.target.value) }}>
                                                                        {period.map((rr) => {
                                                                            return (
                                                                                <MenuItem value={rr}>{rr}</MenuItem>
                                                                            )
                                                                        })
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* VARIABLE */}
                                    <Grid item xl={3} lg={4} md={5} sm={12} xs={3}>
                                        <Grid container>
                                            {/* time label */}
                                            <Grid item xl={12} lg={12} xs={12}>
                                                <Grid container className="sp-variable-container">
                                                    <Typography id="sp-variable-label">Variable</Typography>
                                                </Grid>
                                            </Grid>

                                            {/* per label label */}
                                            <Grid item xl={12} lg={12} xs={12}>
                                                <Grid container className='sp-time-investor-spcap-container'>
                                                    <Grid item xl={5.95} lg={5.95} xs={5.95}>
                                                        <Grid container className="sp-investor-container">
                                                            <Typography id="sp-investor-spcap-label">Investor Return</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xl={5.95} lg={5.95} xs={5.95}>
                                                        <Grid container className="sp-investor-container">
                                                            <Typography id="sp-investor-spcap-label">S&P Cap</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {/* year */}
                                            <Grid item xl={12} lg={12} xs={12}>
                                                <Grid container className='sp-variable-investor-container'>
                                                    <Grid item xl={5.95} lg={5.95} xs={5.95} >
                                                        <Grid container className="sp-variable-investor-container-dropdown">
                                                            <Grid item xl={12} lg={12} xs={12}>
                                                                <FormControl sx={{ m: 0, width: "100%" }} size="small" >
                                                                    <Select
                                                                        labelId="demo-select-small"
                                                                        id="demo-select-small"
                                                                        value={investorReturn}
                                                                        onChange={(e) => { setInvestorReturn(e.target.value) }}>
                                                                        {irList.map((rr) => {
                                                                            return (
                                                                                <MenuItem value={rr}>{rr}%</MenuItem>
                                                                            )
                                                                        })
                                                                        }

                                                                    </Select>
                                                                </FormControl>

                                                            </Grid>

                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xl={5.95} lg={5.95} xs={5.95}>
                                                        <Grid container className="sp-spcap-investor-container">
                                                            <Grid item xl={12} lg={12} xs={12}>
                                                                <FormControl sx={{ m: 0, width: "100%" }} size="small" >
                                                                    <Select
                                                                        labelId="demo-select-small"
                                                                        id="demo-select-small"
                                                                        value={spCap}
                                                                        onChange={(e) => { setSPCap(e.target.value) }}>
                                                                        {spCapList.map((rr) => {
                                                                            return (
                                                                                <MenuItem value={rr}>{rr}%</MenuItem>
                                                                            )
                                                                        })
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* item-2 */}
                            {/* <Grid item xl={12} xs={12}>
                        <Grid container className='sp-tabel-container'>
                            
                            <Grid item xs={1.48}>
                            <Grid container>
                                <Grid item xs={12}>
                                <Grid container className='sp-tabel-start-amt-container' >
                                    <Typography id="sp-tabel-start-amt-label">Start Amount</Typography>
                                </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                <Grid container className='sp-tabel-start-amt-data-container'>
                                    <Typography id="sp-tabel-start-amt-data-label">$100,000</Typography>
                                </Grid>
                                </Grid>
                            </Grid>
                            </Grid>
                            
                            <Grid item xs={10.48}>
                            <Grid container>
                                <Grid item xs={12}>
                                <Grid container className="sp-table-right-container">
                                    <Grid item xs={3.97}>
                                        <Grid container className='sp-tabel-right-three-containers'>
                                            <Typography id="sp-tabel-right-three-containers-label">Ending Amount</Typography>
                                        </Grid>
                                        
                                        <Grid item xs={12}>
                                        <Grid container className='sp-table-right-sub-containers'> 
                                            <Grid item xs={3.95}>
                                            <Grid container className='sp-table-right-sub-container1'>
                                                <Box id="sp-table-right-sub-container-label">S&P</Box>
                                            </Grid>
                                            </Grid>

                                            <Grid item xs={3.95}>
                                            <Grid container className='sp-table-right-sub-container1'>
                                                <Typography id="sp-table-right-sub-container-label">{fullResdata?.mainspfcname}</Typography>
                                            </Grid>
                                            </Grid>

                                            <Grid item xs={3.95}>
                                            <Grid container className='sp-table-right-sub-container1'>
                                                    <Box  id="sp-table-right-sub-container-label">Investor Return</Box>
                                            </Grid>
                                            </Grid>
                                        </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                        <Grid container className='sp-table-right-sub-data-containers'> 
                                            <Grid item xs={3.95}>
                                            <Grid container className='sp-table-right-sub-data-container1'>
                                                <Box id="sp-table-right-sub-data-container-label">{formatNumber(fullResdata?.endAmtSP)}</Box>
                                            </Grid>
                                            </Grid>

                                            <Grid item xs={3.95}>
                                            <Grid container className='sp-table-right-sub-data-container1'>
                                                <Box id="sp-table-right-sub-data-container-label">{formatNumber(fullResdata?.endAmtSPFC)}</Box>
                                            </Grid>
                                            </Grid>

                                            <Grid item xs={3.95}>
                                            <Grid container className='sp-table-right-sub-data-container1'>
                                                <Box id="sp-table-right-sub-data-container-label">{formatNumber(fullResdata?.endAmtIR)}</Box>
                                            </Grid>
                                            </Grid>
                                        </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={3.97}>
                                        <Grid container className='sp-tabel-right-three-containers'>
                                            <Typography id="sp-tabel-right-three-containers-label">Avarage Rate of Return</Typography>
                                        </Grid>
                                        
                                        <Grid item xs={12}>
                                        <Grid container className='sp-table-right-sub-containers'> 
                                            <Grid item xs={3.95}>
                                            <Grid container className='sp-table-right-sub-container1'>
                                                <Box id="sp-table-right-sub-container-label">S&P</Box>
                                            </Grid>
                                            </Grid>

                                            <Grid item xs={3.95}>
                                            <Grid container className='sp-table-right-sub-container1'>
                                                <Box id="sp-table-right-sub-container-label">{fullResdata?.mainspfcname}</Box>
                                            </Grid>
                                            </Grid>

                                            <Grid item xs={3.95}>
                                            <Grid container className='sp-table-right-sub-container1'>
                                                <Box  id="sp-table-right-sub-container-label">Investor Return</Box>
                                            </Grid>
                                            </Grid>
                                        </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                        <Grid container className='sp-table-right-sub-data-containers'> 
                                            <Grid item xs={3.95}>
                                            <Grid container className='sp-table-right-sub-data-container1'>
                                                <Box id="sp-table-right-sub-data-container-label">{(fullResdata?.avgAmtSP)}</Box>
                                            </Grid>
                                            </Grid>

                                            <Grid item xs={3.95}>
                                            <Grid container className='sp-table-right-sub-data-container1'>
                                                <Box id="sp-table-right-sub-data-container-label">{(fullResdata?.avgAmtSPFC)}</Box>
                                            </Grid>
                                            </Grid>

                                            <Grid item xs={3.95}>
                                            <Grid container className='sp-table-right-sub-data-container1'>
                                                <Box id="sp-table-right-sub-data-container-label">{(fullResdata?.avgAmtIR)}</Box>
                                            </Grid>
                                            </Grid>
                                        </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={3.97}>
                                        <Grid container className='sp-tabel-right-three-containers-last'>
                                            <Typography id="sp-tabel-right-three-containers-label">Actual Rate of Return</Typography>
                                        </Grid>
                                        
                                        <Grid container className='sp-table-right-sub-containers'> 
                                            <Grid item xs={3.95}>
                                            <Grid container className='sp-table-right-sub-container1'>
                                                <Box id="sp-table-right-sub-container-label">S&P</Box>
                                            </Grid>
                                            </Grid>

                                            <Grid item xs={3.95}>
                                            <Grid container className='sp-table-right-sub-container1'>
                                                <Box id="sp-table-right-sub-container-label">{fullResdata?.mainspfcname}</Box>
                                            </Grid>
                                            </Grid>

                                            <Grid item xs={3.95}>
                                            <Grid container className='sp-table-right-sub-container1'>
                                                    <Box  id="sp-table-right-sub-container-label">Investor Return</Box>
                                            </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                        <Grid container className='sp-table-right-sub-data-containers'> 
                                            <Grid item xs={3.95}>
                                            <Grid container className='sp-table-right-sub-data-container1'>
                                                <Box id="sp-table-right-sub-data-container-label">{fullResdata?.cmpAmtSP}</Box>
                                            </Grid>
                                            </Grid>

                                            <Grid item xs={3.95}>
                                            <Grid container className='sp-table-right-sub-data-container1'>
                                                <Box id="sp-table-right-sub-data-container-label">{fullResdata?.cmpAmtSPFC}</Box>
                                            </Grid>
                                            </Grid>

                                            <Grid item xs={3.95}>
                                            <Grid container className='sp-table-right-sub-data-container1-last'>
                                                <Box id="sp-table-right-sub-data-container-label">{fullResdata?.cmpAmtIR}</Box>
                                            </Grid>
                                            </Grid>
                                        </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Grid>
                        </Grid> */}

                            {/* ITEM-2*/}
                            <Grid item xl={12} lg={12} md={12} xs={12}>
                                <Grid container className='upsp-table-main-container'>

                                    {window.screen.width > 900 &&
                                        <Grid item xl={1.9} lg={1.9} md={1.9} xs={1.9}>
                                            <Grid container>
                                                <Grid item xl={12} lg={12} md={12} xs={12} className='upsp-start-amt-item'>
                                                    <Grid container className='upsp-start-amt-container'>
                                                        <Grid item xl={12} md={12} lg={12} xs={12}>
                                                            <span className='upsp-start-amt-text'>Start Amount</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xl={12} lg={12} md={12} xs={12} className='upsp-start-amt-value-item'>
                                                    <span className='upsp-start-amt-value-text'>100,000</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }

                                    <Grid item xl={10} lg={10} md={10} xs={10}>
                                        <Grid container>
                                            <Grid item xl={12} lg={12} md={12} xs={12}>
                                                <Grid container>

                                                    <Grid item xl={12} lg={12} md={12} xs={12}>
                                                        <Grid container className='upsp-table-sub-container1'>
                                                            <Grid item xl={2.98} lg={2.98} xs={2.98}></Grid>

                                                            <Grid item xl={2.98} lg={2.98} xs={2.98} className='upsp-ending-amt-item'>
                                                                <span className='upsp-table-heading-text'>Ending Amount</span>
                                                            </Grid>

                                                            <Grid item xl={2.98} lg={2.98} xs={2.98} className='upsp-avg-amt-item'>
                                                                <span className='upsp-table-heading-text'>Average Rate of Return</span>
                                                            </Grid>


                                                            <Grid item xl={2.98} lg={2.98} xs={2.98} className='upsp-actual-amt-item'>
                                                                <span className='upsp-table-heading-text'>Actual Rate of Return</span>
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xl={12} lg={12} md={12} xs={12}>
                                                        <Grid container className='upsp-table-sub-container2'>

                                                            <Grid item xl={2.98} lg={2.98} xs={2.98} className='upsp-sp-head-item2'>
                                                                <span className='upsp-table-heading-text2'>S & P</span>
                                                            </Grid>

                                                            <Grid item xl={2.98} lg={2.98} xs={2.98} className='upsp-sp-end-value-item'>
                                                                <span className='upsp-table-heading-text2'>{formatNumber(fullResdata?.endAmtSP)}</span>
                                                            </Grid>

                                                            <Grid item xl={2.98} lg={2.98} xs={2.98} className='upsp-sp-avg-value-item'>
                                                                <span className='upsp-table-heading-text2'>{(fullResdata?.avgAmtSP)}</span>
                                                            </Grid>

                                                            <Grid item xl={2.98} lg={2.98} xs={2.98} className='upsp-sp-actual-value-item'>
                                                                <span className='upsp-table-heading-text2'>{fullResdata?.cmpAmtSP}</span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xl={12} lg={12} md={12} xs={12}>
                                                        <Grid container className='upsp-table-sub-container3'>

                                                            <Grid item xl={2.98} lg={2.98} xs={2.98} className='upsp-sp-head-item3'>
                                                                <span className='upsp-table-heading-text3'>{fullResdata?.mainspfcname}</span>
                                                            </Grid>

                                                            <Grid item xl={2.98} lg={2.98} xs={2.98} className='upsp-fc-end-value-item'>
                                                                <span className='upsp-table-heading-text3'>{formatNumber(fullResdata?.endAmtSPFC)}</span>
                                                            </Grid>

                                                            <Grid item xl={2.98} lg={2.98} xs={2.98} className='upsp-fc-avg-value-item'>
                                                                <span className='upsp-table-heading-text3'>{(fullResdata?.avgAmtSPFC)}</span>
                                                            </Grid>

                                                            <Grid item xl={2.98} lg={2.98} xs={2.98} className='upsp-fc-actual-value-item'>
                                                                <span className='upsp-table-heading-text3'>{fullResdata?.cmpAmtSPFC}</span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xl={12} lg={12} md={12} xs={12}>
                                                        <Grid container className='upsp-table-sub-container4'>

                                                            <Grid item xl={2.98} lg={2.98} xs={2.98} className='upsp-sp-head-item4'>
                                                                <span className='upsp-table-heading-text4'>Investor Return</span>
                                                            </Grid>

                                                            <Grid item xl={2.98} lg={2.98} xs={2.98} className='upsp-ir-end-value-item'>
                                                                <span className='upsp-table-heading-text4'>{formatNumber(fullResdata?.endAmtIR)}</span>
                                                            </Grid>

                                                            <Grid item xl={2.98} lg={2.98} xs={2.98} className='upsp-ir-avg-value-item'>
                                                                <span className='upsp-table-heading-text4'>{(fullResdata?.avgAmtIR)}</span>
                                                            </Grid>

                                                            <Grid item xl={2.98} lg={2.98} xs={2.98} className='upsp-ir-actual-value-item'>
                                                                <span className='upsp-table-heading-text4'>{fullResdata?.cmpAmtIR}</span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>



                            {/* item-3 */}
                            <Grid item xs={12} >
                                <Grid container className="sp-line-chart">
                                    <Box className="sp-line-chart-graph">
                                        {/* <Line id="sp-line-chart-graph" data={graphData} 	/> */}
                                        {nowShowGrah &&
                                            <CanvasJSChart
                                                chartType="Line"
                                                options={graphDataNew}
                                            // width="100%"
                                            // height="400px"
                                            // options={options}
                                            />
                                        }
                                    </Box>
                                </Grid>
                            </Grid>

                            {/* item-4 */}
                            <Grid item xs={12} >
                                <Grid container className="sp-graph-note">
                                    <Typography id="sp-graph-note-text">*Note: Annual gains and losses in the above calculator reflect S & P 500 Index changes from 1957 forward. Prior to 1957 the numbers reflect changes to the S & P Index.</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* SHARE & EDIT BTN */}
                    <Grid item xl={0.5} lg={0.5} md={0.5} xs={0.5}>
                        <OutputAction data={props.data} inputData={props.inputData} />
                    </Grid>
                </Grid>
            }

            {window.screen.width <= 900 && window.screen.width >= 320 &&
                <>
                    <Grid container>
                        <Grid item sm={11} xs={11} className='md-upsp-main-item'>
                            <Grid container>
                                {props.timeButton == "time" &&
                                    <Grid item xs={12}>
                                        <Grid container className='md-upsp-time-period-container'>

                                            <Grid item xs={12} className='md-upsp-time-head-item'>
                                                <span className='md-upsp-time-period-text'>Time Period</span>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container className='md-upsp-start-end-container'>
                                                    <Grid item xs={5.95} className='md-upsp-start-item'>
                                                        <span>Start Period</span>
                                                    </Grid>
                                                    <Grid item xs={5.95} className='md-upsp-end-item'>
                                                        <span>End Year</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container className='md-upsp-start-end-dropdwn-container'>
                                                    <Grid item xs={5.95} className='md-upsp-start-dropdwn-item'>
                                                        <FormControl sx={{ m: 0, width: "100% " }} size="small" >
                                                            <Select
                                                                className='upsp-start-drowpdown'
                                                                labelId="demo-select-small"
                                                                id="demo-select-small"
                                                                value={startPeriod}
                                                                onChange={(e) => { setStartPeriod(e.target.value) }}>
                                                                {period.map((rr) => {
                                                                    return (
                                                                        <MenuItem value={rr}>{rr}</MenuItem>
                                                                    )
                                                                })
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={5.95} className='md-upsp-end-dropdwn-item'>
                                                        <FormControl sx={{ m: 0, width: "100%" }} size="small" >
                                                            <Select
                                                                className='upsp-end-drowpdown'
                                                                labelId="demo-select-small"
                                                                id="demo-select-small"
                                                                name="endPeriod"
                                                                value={endPeriod}
                                                                onChange={(e) => { setEndPeriod(e.target.value) }}>
                                                                {period.map((rr) => {
                                                                    return (
                                                                        <MenuItem value={rr}>{rr}</MenuItem>
                                                                    )
                                                                })
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                }
                                {props.timeButton == "variable" &&
                                    <Grid item xs={12}>
                                        <Grid container className='md-upsp-variable-period-container' >

                                            <Grid item xs={12} className='md-upsp-time-head-item'>
                                                <span className='md-upsp-time-period-text'>Variable</span>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container className='md-upsp-start-end-container'>
                                                    <Grid item xs={5.95} className='md-upsp-start-item'>
                                                        <span>Investor Return</span>
                                                    </Grid>
                                                    <Grid item xs={5.95} className='md-upsp-end-item'>
                                                        <span>S & P Cap</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container className='md-upsp-start-end-dropdwn-container'>
                                                    <Grid item xs={5.95} className='md-upsp-start-dropdwn-item'>
                                                        <FormControl sx={{ m: 0, width: "100%" }} size="small" >
                                                            <Select
                                                                className='md-upsp-investor-dropdwn'
                                                                labelId="demo-select-small"
                                                                id="demo-select-small"
                                                                value={investorReturn}
                                                                onChange={(e) => { setInvestorReturn(e.target.value) }}>
                                                                {irList.map((rr) => {
                                                                    return (
                                                                        <MenuItem value={rr}>{rr}%</MenuItem>
                                                                    )
                                                                })
                                                                }

                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={5.95} className='md-upsp-end-dropdwn-item'>
                                                        <FormControl sx={{ m: 0, width: "100%" }} size="small" >
                                                            <Select
                                                                className='md-upsp-sp-dropdwn'
                                                                labelId="demo-select-small"
                                                                id="demo-select-small"
                                                                value={spCap}
                                                                onChange={(e) => { setSPCap(e.target.value) }}>
                                                                {spCapList.map((rr) => {
                                                                    return (
                                                                        <MenuItem value={rr}>{rr}%</MenuItem>
                                                                    )
                                                                })
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                }


                                <Grid item sm={12} xs={12}>
                                    <Grid container className='upsp-start-amt-main-container'>
                                        <Grid item sm={6} xs={6} className='upsp-start-amt-item'>
                                            <Grid container className='upsp-start-amt-container'>
                                                <Grid item sm={6} xs={12}>
                                                    <span className='upsp-start-amt-text'>Start Amount</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={6} xs={6} className='upsp-start-amt-value-item'>
                                            <span className='upsp-start-amt-value-text'>100,000</span>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item sm={12} xs={12}>
                                    <Grid container className='md-upsp-table-main-container1'>
                                        <Grid item sm={12} xs={12} className='md-upsp-end-amt-item'>
                                            <span className='md-upsp-table-head-text'>Ending Amount</span>
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <Grid container className='md-upsp-end-amt-main-container'>
                                                <Grid item sm={3.95} xs={3.95} className='md-upsp-end-amt-head-item1'>
                                                    <span className='md-upsp-head-text'>S & P</span>
                                                </Grid>
                                                <Grid item sm={3.95} xs={3.95} className='md-upsp-end-amt-head-item1'>
                                                    <span className='md-upsp-head-text'>{fullResdata?.mainspfcname}</span>
                                                </Grid>
                                                <Grid item sm={3.95} xs={3.95} className='md-upsp-end-amt-head-item1'>
                                                    <span className='md-upsp-head-text'>Investor Return</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <Grid container className='md-upsp-end-value-main-container'>

                                                <Grid item sm={3.95} xs={3.95} className='md-upsp-end-amt-head-item2'>
                                                    <span className='md-upsp-head-value'>{formatNumber(fullResdata?.endAmtSP)}</span>
                                                </Grid>
                                                <Grid item sm={3.95} xs={3.95} className='md-upsp-end-amt-head-item2'>
                                                    <span className='md-upsp-head-value'>{formatNumber(fullResdata?.endAmtSPFC)}</span>
                                                </Grid>
                                                <Grid item sm={3.95} xs={3.95} className='md-upsp-end-amt-head-item2'>
                                                    <span className='md-upsp-head-value'>{formatNumber(fullResdata?.endAmtIR)}</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item sm={12} xs={12}>
                                    <Grid container className='md-upsp-table-main-container2' >

                                        <Grid item sm={12} xs={12} className='md-upsp-end-amt-item'>
                                            <span className='md-upsp-table-head-text'>Average Rate of Return</span>
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <Grid container className='md-upsp-end-amt-main-container'>
                                                <Grid item sm={3.95} xs={3.95} className='md-upsp-end-amt-head-item1'>
                                                    <span className='md-upsp-head-text'>S & P</span>
                                                </Grid>
                                                <Grid item sm={3.95} xs={3.95} className='md-upsp-end-amt-head-item1'>
                                                    <span className='md-upsp-head-text'>{fullResdata?.mainspfcname}</span>
                                                </Grid>
                                                <Grid item sm={3.95} xs={3.95} className='md-upsp-end-amt-head-item1'>
                                                    <span className='md-upsp-head-text'>Investor Return</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <Grid container className='md-upsp-end-value-main-container'>
                                                <Grid item sm={3.95} xs={3.95} className='md-upsp-end-amt-head-item2'>
                                                    <span className='md-upsp-head-value'>{(fullResdata?.avgAmtSP)}</span>
                                                </Grid>
                                                <Grid item sm={3.95} xs={3.95} className='md-upsp-end-amt-head-item2'>
                                                    <span className='md-upsp-head-value'>{(fullResdata?.avgAmtSPFC)}</span>
                                                </Grid>
                                                <Grid item sm={3.95} xs={3.95} className='md-upsp-end-amt-head-item2'>
                                                    <span className='md-upsp-head-value'>{(fullResdata?.avgAmtIR)}</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item sm={12} xs={12}>
                                    <Grid container className='md-upsp-table-main-container3'>
                                        <Grid item sm={12} xs={12} className='md-upsp-end-amt-item'>
                                            <span className='md-upsp-table-head-text'>Actual Rate of Return</span>
                                        </Grid>

                                        <Grid item sm={12} xs={12} >
                                            <Grid container className='md-upsp-end-amt-main-container'>
                                                <Grid item sm={3.95} xs={3.95} className='md-upsp-end-amt-head-item1'>
                                                    <span className='md-upsp-head-text'>S & P</span>
                                                </Grid>
                                                <Grid item sm={3.95} xs={3.95} className='md-upsp-end-amt-head-item1'>
                                                    <span className='md-upsp-head-text'>{fullResdata?.mainspfcname}</span>
                                                </Grid>
                                                <Grid item sm={3.95} xs={3.95} className='md-upsp-end-amt-head-item1'>
                                                    <span className='md-upsp-head-text'>Investor Return</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <Grid container className='md-upsp-end-value-main-container'>
                                                <Grid item sm={3.95} xs={3.95} className='md-upsp-end-amt-head-item2'>
                                                    <span className='md-upsp-head-value'>{fullResdata?.cmpAmtSP}</span>
                                                </Grid>
                                                <Grid item sm={3.95} xs={3.95} className='md-upsp-end-amt-head-item2'>
                                                    <span className='md-upsp-head-value'>{fullResdata?.cmpAmtSPFC}</span>
                                                </Grid>
                                                <Grid item sm={3.95} xs={3.95} className='md-upsp-end-amt-head-item2'>
                                                    <span className='md-upsp-head-value'>{fullResdata?.cmpAmtIR}</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item sm={12} xs={12} className='md-upsp-grah-item'>
                                    <Box className="sp-line-chart-graph">
                                        {/* <Line id="sp-line-chart-graph" data={graphData} 	/> */}
                                        {nowShowGrah &&
                                            <CanvasJSChart
                                                chartType="Line"
                                                options={graphDataNew}
                                            // width="100%"
                                            // height="400px"
                                            // options={options}
                                            />
                                        }
                                    </Box>
                                </Grid>

                                <Grid item sm={12} xs={12} className='md-upsp-graph-text-item' >
                                    <span className='md-upsp-graph-text'>*Note: Annual gains and losses in the above calculator reflect S & P 500 Index changes from 1957 forward. Prior to 1957 the numbers reflect changes to the S & P Index.</span>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>


                </>
            }

        </>
    );
}

export default UPSPComparison;