import React from 'react'
import './Footer.css';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import Link from '@mui/material/Link';
const Footer = () => {
    return (
        <>
            <Grid container className="footer-main-container"  >
                <Grid item xl={4} lg={4} md={4} sm={12}>
                    <Grid container className='footer-copyright-container'>
                        <Grid item xl={12} lg={12} md={12} sm={12} className='footer-copyrights-item'> 
                           <span className='footer-copyright-text'>  Copyright © All Rights Reserved 2022. </span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xl={4} lg={6} md={6} sm={12} textAlign="center">
                    <Grid container className='footer-policy-container' >
                        <Grid item xl={12} lg={12} md={12} sm={12} textAlign="center" >
                            <Grid container className='footer-policy-sub-container'>

                                <Grid item className='footer-tc-item' xl={4} lg={3.5} md={4} sm={3}>
                                    <Link href="/termsandconditions" target="_blank" class="footer-tc-text">Terms and Conditions</Link>
                                </Grid>

                                <Grid item className='footer-tc-slash-item' lg={0.4} md={0.5} >|</Grid>

                                <Grid item className='footer-pc-item' xl={3} lg={2.5} md={3} sm={2}>
                                    <Link href="/privacypolicy" target="_blank" class="footer-pc-text">Privacy Policy</Link>
                                </Grid>

                                <Grid item className='footer-pc-slash-item' lg={0.4} md={0.5} >|</Grid>

                                <Grid className='footer-ua-slash-item' item xl={3} lg={3} md={3} sm={2}>
                                    <Link href="/useragreement" target="_blank" class="footer-ua-text">User Agreement</Link>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Footer;