import { Grid } from '@mui/material'
import React from 'react'
import Link from '@mui/material/Link';

import "./MobileFooter.css"

export const MobileFooter = (props) => {
    return (
        <>
            <Grid container className={`mobileFooter ${props.className}`} alignItems="center">
                <Grid item xs={12}>
                    <Grid container justifyContent="center">
                        <Grid item xs={8} textAlign="center" className='mbf-copy-right-label'>
                            Copyright © All Rights Reserved 2022.
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="center">
                        <Grid item xs={4}>
                            <Link href="/termsandconditions" target="_blank" className='fClass' >Terms and Conditions</Link>
                        </Grid>
                        <Grid item xs={0.5}>|</Grid>
                        <Grid item xs={3}>
                            <Link href="/privacypolicy" target="_blank" className='fClass'>Privacy Policy</Link>
                        </Grid>
                        <Grid item xs={0.5}>|</Grid>
                        <Grid item xs={3}>
                            <Link href="/useragreement" target="_blank" className='fClass'>User Agreement</Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
