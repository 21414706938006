import Axios from "./axios";

export const LoginAPI = async (data, onSuccess, onError) => {
    try {

        const response = await Axios.post('auth/login', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        console.log("printing response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

export const UserMeAPI = async (onSuccess, onError) => {
    try {
        const response = await Axios.get('global/me', {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing response of user me", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

export const sendVerificationCode = async (onSuccess, onError) => {
    try {
        const responce = await Axios.get('global/send-verification-code', {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing responce of user me", responce)
        onSuccess && onSuccess(responce);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

export const getCompanyLogo = async (onSuccess, onError) => {
    try {
        const response = await Axios.get('global/get-company-logo', {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing response of user me", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

export const LogoutUserAPI = async (data, onSuccess, onError) => {
    try {
        // console.log("Printing data of logout service file", data);
        const response = await Axios.post("/logout", data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });

        onSuccess && onSuccess(response);
    }
    catch (err) {
        onError && onError(err);
    }
}

export const getCompanyAgentList = async (onSuccess, onError) => {
    try {
        var url = `global/get-agent-details`;
        const response = await Axios.get(url, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

export const getAgentList = async (onSuccess, onError) => {
    try {
        var url = `global/get-agent-details`;
        const response = await Axios.get(url, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });

        // console.log("printing response getAgentList -", response)
        if (response.data.status) {
            response.data.data[0].agents.map(rr => {
                rr['sorting_status_id'] = "b"
                if (rr['user_status_id'] == "2") {
                    rr['sorting_status_id'] = "a"
                }
            })
        }
        // console.log("printing response getAgentList 2-", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

export const getUserProfileDetails = async (data, onSuccess, onError) => {
    try {
        var url = 'global/get-user-details?agent_id=' + data;
        const response = await Axios.get(url, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}


export const getparticularUserProfileDetail = async (data, onSuccess, onError) => {
    try {
        var url = 'user/get-user-profile-detail?user_profile_id=' + data;
        const response = await Axios.get(url, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

export const getparticularUserProfileDetailPublic = async (data, onSuccess, onError) => {
    try {
        var url = 'user/get-user-profile-detail?token=' + data.token + "&user_profile_id=" + data.profile_id;
        const response = await Axios.get(url, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        // console.log("printing response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

export const getActivityLog = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get("global/get-url-token-data?user_profile_id=" + data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

// duplicate

export const createNewUserProfile = async (data, onSuccess, onError) => {
    try {
        console.log("ID Data abc ", data);
        const response = await Axios.post('user/create-new-user-profile', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}
export const updateUserProfile = async (data, onSuccess, onError) => {
    try {
        // console.log("ID Data abc ",data);
        const response = await Axios.put('user/update-user-profile', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing updateUserProfile response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}
export const updateCookieData = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.put('user/update-cookie-data', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        console.log("printing updateCookieData response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

export const updateUserAssignedAgent = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.put('user/update-user-assigned-agent', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        console.log("printing response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

export const deleteUserProfileUrl = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.delete('global/delete-user-profile-url?public_token=' + data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

export const deleteUserProfile = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.delete('user/delete-user-profile?user_profile_id=' + data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

export const logoutApiCall = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post('auth/logout', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

export const getGraphDetails = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post('global/get-graph-details', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

export const getGraphDetailsPublic = async (data, onSuccess, onError) => {
    try {
        let url = 'global/get-graph-details?token=' + data.token
        const response = await Axios.post(url, data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

export const SaveToSampleProfile = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post('global/get-graph-details', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}


export const generateClientFacingURL = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post('auth/generate-client-facing-url-token', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

// generateClientFacingURL-d
export const generateClientFacingURLAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post('auth/create-client-facing-url', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

// activitylog-d
export const getClientFacingUrlDataAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get("global/get-client-facing-url-data?user_profile_id=" + data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

// 550-sendMailProfileEmailAgain
export const sendEmailUrlAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post('global/send-email-url', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(response);

    } catch (err) {
        onError && onError(err)
    }
}

// 295 - deleteUserProfileUrl
export const deleteUserProfileUrlAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.delete('global/delete-client-facing-url?public_token=' + data.public_token + '&user_profile_id=' + data.user_profile_id, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

export const extendUrlAPI = async (data, onSuccess, onError) => {
    try {
        // console.log("Printing in extend url expiry", data);
        const response = await Axios.put('global/extend-url-expiry', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("Printing response for in extend url expiry", response);
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("Printing error in extend url expiry", err);
        onError && onError(err);
    }
}







export const registerNewUser = async (data, onSuccess, onError) => {
    try {
        // console.log("Printing payload to pass for register new user", data);
        const response = await Axios.post('user/registration', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("Printing response for register new user", response);
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("Printing error for register new user", err);
        onError && onError(err);
    }
}

export const getUserRoleDetail = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get('global/get-user-role-details', {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("Printing response for get user detail role", response);
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("Printing error for get user detail role", err);
        onError && onError(err);
    }
}

export const removeAgent = async (data, onSuccess, onError) => {
    try {
        // console.log("Pritnig data for removing agent", data);
        const response = await Axios.delete('user/remove-agent?agent_id=' + data.agentId, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("Printing response for removing agent", response);
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("Printing error for removing agent", err);
        onError && onError(err);
    }
}

export const forgotpasswordApiCall = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post('auth/forget-password', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err)
    }
}
export const resetPasswordAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post('auth/reset-password?email=' + data.email + '&verify_token=' + data.token, data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(response);
    } catch (err) {
        onError && onError(err)
    }
}



export const sendMailProfileEmailAgain = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post('global/send-url-mail', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(response);

    } catch (err) {
        onError && onError(err)
    }
}

export const getStatusDetails = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get('global/get-status-details', {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("Printing error for get status detail", err);
        onError && onError(err);
    }
}

export const inActiveAgent = async (data, onSuccess, onError) => {
    try {
        // console.log("Printing in active agent data", data);
        const response = await Axios.put('auth/inactive-agent', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("Printing response for in active agent", response);
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("Printing error for in active agent", err);
        onError && onError(err);
    }
}

export const updateAgentData = async (data, onSuccess, onError) => {
    try {
        // console.log("Printing payload to pass for register new user", data);
        const response = await Axios.put('user/update-agent-data', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("Printing response for register new user", response);
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("Printing error for register new user", err);
        onError && onError(err);
    }
}

export const acceptTermsandConditionAPI = async (onSuccess, onError) => {
    try {
        var data = {
            "user_agreement_flag": true
        }
        const response = await Axios.put('user/update-agent-data', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("Printing response for register new user", response);
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("Printing error for register new user", err);
        onError && onError(err);
    }
}

//N TrainingPageDocPopup :-
export const uploadFilesAPI = async (data, onSuccess, onError) => {
    try {

        const response = await Axios.post('resource/upload-resource', data, {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true
        });
        // console.log("Printing response for get user detail role", response);
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("Printing error for get user detail role", err);
        onError && onError(err);
    }
}

//N TrainingPageDocPopup :-
export const createResourceAPI = async (data, onSuccess, onError) => {
    try {

        const response = await Axios.post('resource/create-new-resource', JSON.stringify(data), {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        });
        // console.log("Printing response for get user detail role", response);
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("Printing error for get user detail role", err);
        onError && onError(err);
    }
}

//N TrainingPageDocPopup :-
export const updateResourceAPI = async (data, onSuccess, onError) => {
    try {
        // console.log("ID Data abc ",data);
        const response = await Axios.put('resource/update-resource', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing updateDocumentAPI response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}


//N TrainingPage :-
export const getUploadResourceData = async (data, onSuccess, onError) => {
    try {
        let url = 'resource/get-upload-resource-data?resource_id=' + data.thumbnail_file_id + "&image_flag=" + data.imageflag
        const response = await Axios.get(url, {
            headers: { 'content-type': 'application/json' },
            withCredentials: true
        });
        // console.log("Printing response for get user detail role", response);
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("Printing error for get user detail role", err);
        onError && onError(err);
    }
}


//N TrainingPage :-
export const getCompanyresourceData = async (onSuccess, onError) => {
    try {

        const response = await Axios.get('resource/get-company-resource-data', {
            headers: { 'content-type': 'application/json' },
            withCredentials: true
        });
        // console.log("Printing response for get user detail role", response);
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("Printing error for get user detail role", err);
        onError && onError(err);
    }
}

//N TrainingPage :-
export const delteResourceAPI = async (data, onSuccess, onError) => {
    try {
        // console.log('Data In API Call :::::::::::::::::::::::::::',data.resource_id)
        const response = await Axios.delete('resource/delete-resource?resource_id=' + data.resource_id, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}



// TraininPageDocPopup
export const updateDocumentAPI = async (data, onSuccess, onError) => {
    try {
        // console.log("ID Data abc ",data);
        const response = await Axios.put('resource/update-training-resource-data', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing updateDocumentAPI response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

// TraininPageDocPopup
export const uploadTrainingResourceAPI = async (data, onSuccess, onError) => {
    try {

        const response = await Axios.post('resource/upload-training-resource', data, {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true
        });
        // console.log("Printing response for get user detail role", response);
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("Printing error for get user detail role", err);
        onError && onError(err);
    }
}


// TrainingPage
export const getResourceDataAPI = async (onSuccess, onError) => {
    try {

        const response = await Axios.get('resource/get-training-resource-data', {
            headers: { 'content-type': 'application/json' },
            withCredentials: true
        });
        // console.log("Printing response for get user detail role", response);
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("Printing error for get user detail role", err);
        onError && onError(err);
    }
}

// TrainingPage
export const delteResourceDataAPI = async (data, onSuccess, onError) => {
    try {
        // console.log('Data In API Call :::::::::::::::::::::::::::',data.resource_id)
        const response = await Axios.delete('resource/delete-training-resource-data?resource_id=' + data.resource_id, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}


export const verifyUserAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get('auth/verify-user?verify_token=' + data.verify_token, data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(response)
    } catch (err) {
        onError && onError(err)
    }
}

export const resendVerifyUserAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.post('auth/resend-verification-link', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(response)
    } catch (err) {
        onError && onError(err)
    }
}

export const urlTokenDataAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get('global/get-url-token-data', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(response)
    } catch (err) {
        onError && onError(err)
    }
}


export const sendProfileUrlAPI = async (data, onSuccess, onError) => {
    try {
        const response = await Axios.get('global/get-url-token-data', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(response)
    } catch (err) {
        onError && onError(err)
    }
}


// Report
export const getCompanyAgentDetailsAPI = async (onSuccess, onError) => {
    try {

        const response = await Axios.get('global/get-agent-details', {
            headers: { 'content-type': 'application/json' },
            withCredentials: true
        });
        // console.log("Printing response for get company agent detail role", response);
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("Printing error for get user detail role", err);
        onError && onError(err);
    }
}

// report
export const getUserDetailsAPI = async (data, onSuccess, onError) => {
    try {
        let url = 'global/get-user-details';
        if (data.agentid) {
            url += "?agent_id=" + data.agentid;
        } else {
            url += "?agent_id=0";
        }

        if (data.date_filter_flag) {
            url += "&date_filter_flag=true";
            if (data.from_timestamp && data.to_timestamp) {
                url += "&from_timestamp=" + data.from_timestamp + "&to_timestamp=" + data.to_timestamp
            }
            else if (data.from_timestamp) {
                url += "&from_timestamp=" + data.from_timestamp
            }
            else if (data.to_timestamp) {
                url += "&to_timestamp=" + data.to_timestamp
            }
        }
        else {
            url += "&date_filter_flag=false";
        }

        const response = await Axios.get(url, {
            headers: { 'content-type': 'application/json' },
            withCredentials: true
        });
        // console.log("Printing response for get user detail role", response);
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("Printing error for get user detail role", err);
        onError && onError(err);
    }
}

export const changePasswordAPI = async (data, onSuccess, onError) => {
    try {
        // console.log("ID Data abc ",data);
        const responce = await Axios.put('auth/reset-password', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("Printing responce for password change api", responce)
        onSuccess && onSuccess(responce);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

export const pendingApprovalAPI = async (data, onSuccess, onError) => {
    try {
        // console.log("ID Data abc ",data);
        const response = await Axios.put('auth/agent-approval-request', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        // console.log("printing updateUserProfile response", response)
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error", err);
        onError && onError(err)
    }
}

