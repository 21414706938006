import React from 'react'
import './ClientPageUrlSaveEmailPopup.css';
import { Dialog, DialogContent, Grid, TextField, Typography, Button, Box, InputAdornment } from "@mui/material"
import { useState } from 'react';
import Sucessmark from '../../assets/Images/Sucessmark.png'




const ClientPageUrlSaveEmailPopup = (props) => {
    return(
        <Dialog
            open={props.ClientPageUrlSaveEmailPopup}
            onClose={props.handleClose}
            aria-labelledby="responsive-dialog-title">
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container className='clienturl-main-container'>
                            
                            <Grid item xs={12} className="clienturl-save-email-sucess-item">
                                <span id="clienturl-save-email-sucess-text">Successfully Saved!</span>
                            </Grid>
                            
                            <Grid item xs={12} className="clienturl-save-email-sucess-img-item">
                                <img src={Sucessmark} alt="" />
                            </Grid>
                            
                            <Grid item xs={12} className="clienturl-save-email-sucess-text-item" >
                                <span id="client-save-email-sucess-text2">Email sent and client page URL saved sucessfully!</span>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container className="clienturl-save-email-close-button-container">
                                    <Grid item xs={4} className="clienturl-save-email-close-button-item">
                                        <Button variant='contained' id="clienturl-save-email-close-button" onClick={props.handleClose}>Close</Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ClientPageUrlSaveEmailPopup;